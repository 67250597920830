import { Region } from 'generated/generated-graphql'
// PACKAGING TYPES

enum PackagingTypeTranslationKey {
  bottle = 'bottle',
  can = 'can',
  keg = 'keg',
  'pouch_/_container_/_box' = 'pouch_/_container_/_box',
}

const UKPackagingTypeTranslationKeysMap: Record<
  string,
  PackagingTypeTranslationKey
> = {
  bottle: PackagingTypeTranslationKey.bottle,
  can: PackagingTypeTranslationKey.can,
  keg: PackagingTypeTranslationKey.keg,
  'pouch / container / box':
    PackagingTypeTranslationKey['pouch_/_container_/_box'],
}

const AUPackagingTypeTranslationKeysMap: Record<
  string,
  PackagingTypeTranslationKey
> = {
  bottle: PackagingTypeTranslationKey.bottle,
  can: PackagingTypeTranslationKey.can,
  keg: PackagingTypeTranslationKey.keg,
  'pouch / container / box':
    PackagingTypeTranslationKey['pouch_/_container_/_box'],
}

const ESPackagingTypeTranslationKeysMap: Record<
  string,
  PackagingTypeTranslationKey
> = {
  botella: PackagingTypeTranslationKey.bottle,
  lata: PackagingTypeTranslationKey.can,
  barril: PackagingTypeTranslationKey.keg,
  'cajas / envases / bolsas':
    PackagingTypeTranslationKey['pouch_/_container_/_box'],
}

export const PackagingTypeTranslationsKeysMap = {
  [Region.UnitedKingdom]: UKPackagingTypeTranslationKeysMap,
  [Region.Australia]: AUPackagingTypeTranslationKeysMap,
  [Region.Spain]: ESPackagingTypeTranslationKeysMap,
}

// VENUE CATEGORIES

enum VenueCategoryTranslationKey {
  pub = 'pub',
  restaurant = 'restaurant',
  hotel = 'hotel',
  'festivals_&_events' = 'festivals_&_events',
  bar = 'bar',
  'off-trade' = 'off-trade',
  club = 'club',
  'beach_club' = 'beach_club',
  discoteca = 'discoteca',
  'pool_club' = 'pool_club',
}

const UKVenueCategoryTranslationKeysMap: Record<
  string,
  VenueCategoryTranslationKey
> = {
  Bar: VenueCategoryTranslationKey.bar,
  Restaurant: VenueCategoryTranslationKey.restaurant,
  Hotel: VenueCategoryTranslationKey.hotel,
  'Festivals & Events': VenueCategoryTranslationKey['festivals_&_events'],
  Pub: VenueCategoryTranslationKey.pub,
  'Off-Trade': VenueCategoryTranslationKey['off-trade'],
  Club: VenueCategoryTranslationKey.club,
}

const AUVenueCategoryTranslationKeysMap: Record<
  string,
  VenueCategoryTranslationKey
> = {
  Bar: VenueCategoryTranslationKey.bar,
  Restaurant: VenueCategoryTranslationKey.restaurant,
  Hotel: VenueCategoryTranslationKey.hotel,
  'Festivals & Events': VenueCategoryTranslationKey['festivals_&_events'],
  Pub: VenueCategoryTranslationKey.pub,
  'Off-Trade': VenueCategoryTranslationKey['off-trade'],
  Club: VenueCategoryTranslationKey.club,
}

const ESVenueCategoryTranslationKeysMap: Record<
  string,
  VenueCategoryTranslationKey
> = {
  Bar: VenueCategoryTranslationKey.bar,
  Restaurante: VenueCategoryTranslationKey.restaurant,
  Hotel: VenueCategoryTranslationKey.hotel,
  'Eventos & Festivales': VenueCategoryTranslationKey['festivals_&_events'],
  Pub: VenueCategoryTranslationKey.pub,
  'Off-Trade': VenueCategoryTranslationKey['off-trade'],
  Club: VenueCategoryTranslationKey.club,
  'Beach Club': VenueCategoryTranslationKey.beach_club,
  Discoteca: VenueCategoryTranslationKey.discoteca,
  'Pool Club': VenueCategoryTranslationKey.pool_club,
}

export const VenueCategoryTranslationsKeysMap = {
  [Region.UnitedKingdom]: UKVenueCategoryTranslationKeysMap,
  [Region.Australia]: AUVenueCategoryTranslationKeysMap,
  [Region.Spain]: ESVenueCategoryTranslationKeysMap,
}

// LISTING TYPES

enum ListingTypeTranslationKey {
  house_pour = 'house_pour',
  featured_drink = 'featured_drink',
  standard_listing = 'standard_listing',
  'event' = 'event',
}

const UKListingTypeTranslationKeysMap: Record<
  string,
  ListingTypeTranslationKey
> = {
  'House Pour': ListingTypeTranslationKey.house_pour,
  'Featured Drink': ListingTypeTranslationKey.featured_drink,
  'Standard Listing': ListingTypeTranslationKey.standard_listing,
  Event: ListingTypeTranslationKey.event,
}

const AUListingTypeTranslationKeysMap: Record<
  string,
  ListingTypeTranslationKey
> = {
  'House Pour': ListingTypeTranslationKey.house_pour,
  'Featured Drink': ListingTypeTranslationKey.featured_drink,
  'Standard Listing': ListingTypeTranslationKey.standard_listing,
  Event: ListingTypeTranslationKey.event,
}

const ESListingTypeTranslationKeysMap: Record<
  string,
  ListingTypeTranslationKey
> = {
  'Tiro de la casa': ListingTypeTranslationKey.house_pour,
  'Bebida Destacada': ListingTypeTranslationKey.featured_drink,
  'Listado en Carta': ListingTypeTranslationKey.standard_listing,
  Evento: ListingTypeTranslationKey.event,
}

export const ListingTypeTranslationsKeysMap = {
  [Region.UnitedKingdom]: UKListingTypeTranslationKeysMap,
  [Region.Australia]: AUListingTypeTranslationKeysMap,
  [Region.Spain]: ESListingTypeTranslationKeysMap,
}
