import { UrlObject } from 'url'

import { CSSProperties } from 'react'

import { Button, ButtonProps } from '@chakra-ui/react'
import Link from 'next/link'

interface NextButtonProps extends ButtonProps {
  href: string | UrlObject
  linkStyle?: CSSProperties
}

export const NextButton = ({
  href,
  children,
  linkStyle,
  ...rest
}: NextButtonProps) => (
  <Link href={href} passHref style={linkStyle}>
    <Button {...rest}>{children}</Button>
  </Link>
)
