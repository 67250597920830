import { Flex, FlexProps, Link, Text, TextProps } from '@chakra-ui/react'

import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
  Twitter as TwitterIcon,
} from 'components/icons'
import { Region } from 'generated/generated-graphql'
import { useRegion } from 'providers/RegionProvider'

export enum SocialMedia {
  LinkedIn,
  Facebook,
  Twitter,
  Instagram,
}

const IconProps = {
  color: 'brand.americanBlue',
  fill: 'brand.brightGray',
  _hover: { color: 'white', fill: 'brand.ultramarine' },
  _groupHover: { color: 'white', fill: 'brand.ultramarine' },
  _active: { color: 'white', fill: 'brand.ultramarine' },
}

const renderSocialMediaIcon = ({
  platform,
  iconSize,
  region,
}: {
  platform: SocialMedia
  iconSize: string
  region: Region
}) => {
  switch (platform) {
    case SocialMedia.LinkedIn:
      return (
        <Link
          href={
            region === Region.Australia
              ? 'https://www.linkedin.com/company/eztenda-australia/'
              : 'https://www.linkedin.com/company/eztenda/'
          }
        >
          <LinkedInIcon width={iconSize} height={iconSize} {...IconProps} />
        </Link>
      )
    case SocialMedia.Facebook:
      return (
        // TODO Wrap with LINK once facebook handle will be ready
        <FacebookIcon width={iconSize} height={iconSize} {...IconProps} />
      )
    case SocialMedia.Twitter:
      return (
        <Link href="https://twitter.com/EzTenda">
          <TwitterIcon width={iconSize} height={iconSize} {...IconProps} />
        </Link>
      )
    case SocialMedia.Instagram:
      return (
        <Link href="https://www.instagram.com/eztenda/">
          <InstagramIcon width={iconSize} height={iconSize} {...IconProps} />
        </Link>
      )
    default:
      return null
  }
}

interface SocialMediaIconProps extends FlexProps {
  platform: SocialMedia
  iconSize: string
  label?: string
  labelProps?: TextProps
}

export const SocialMediaIcon = ({
  platform,
  label,
  iconSize,
  labelProps,
  ...rest
}: SocialMediaIconProps) => {
  const { region } = useRegion()

  return (
    <Flex role="group" cursor="pointer" align="center" {...rest}>
      {renderSocialMediaIcon({ platform, iconSize, region })}
      {label && (
        <Text
          _groupHover={{ color: 'brand.ultramarine' }}
          ml="16px"
          fontSize="md"
          lineHeight="25px"
          {...labelProps}
        >
          {label}
        </Text>
      )}
    </Flex>
  )
}
