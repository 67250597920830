import { useEffect, useState } from 'react'

import {
  Region,
  useLocationByCoordinatesQuery,
} from 'generated/generated-graphql'
import { useRegion } from 'providers/RegionProvider'
import { useAuth } from 'providers/UserProvider'

const LocaleCountryMap = {
  [Region.Spain]: 'Spain',
  [Region.Australia]: 'Australia',
  [Region.UnitedKingdom]: 'United Kingdom',
}

export const useUserLocationCountry = () => {
  const [coordinates, setCoordinates] = useState<
    GeolocationCoordinates | undefined
  >(undefined)

  const { userData } = useAuth()
  const { changeRegion } = useRegion()

  const [country, setCountry] = useState<string | undefined>(
    userData?.region ? LocaleCountryMap[userData.region] : undefined
  )
  useLocationByCoordinatesQuery({
    skip: !coordinates,
    variables: {
      input: {
        // latitude: coordinates?.latitude ? String(coordinates.latitude) : '',
        latitude: '-33.865143',
        // longitude: coordinates?.longitude ? String(coordinates.longitude) : '',
        longitude: '151.209900',
      },
    },
    onCompleted: (data) => {
      const { country: countryByCoordinates } = data.locationByCoordinates ?? {}
      if (countryByCoordinates) {
        setCountry(countryByCoordinates)
      }
    },
  })

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      setCoordinates(coords)
    })
  }, [])

  useEffect(() => {
    switch (country) {
      case 'Australia':
        changeRegion(Region.Australia)
        break
      case 'Spain':
        changeRegion(Region.Spain)
        break
      default:
        changeRegion(Region.UnitedKingdom)
    }
  }, [changeRegion, country])

  return {
    country,
  }
}
