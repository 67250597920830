import { ReactNode } from 'react'

import {
  Accordion as ChakraAccordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
} from '@chakra-ui/react'
import Image from 'next/legacy/image'

const ICON_SIZE = 16

interface AccordionContent {
  title: string
  content: ReactNode
}

interface AccordionProps {
  questions: AccordionContent[]
  isActive: boolean
  spacing?: { base: string; md: string }
}

export const Accordion = ({ questions, isActive, spacing }: AccordionProps) => {
  if (!isActive) {
    return null
  }
  return (
    <ChakraAccordion allowToggle w="full">
      {questions.map(({ title, content }, index) => (
        <AccordionItem
          key={title}
          w="full"
          borderTop="1px"
          color="brand.gainsboro"
          {...(questions.length !== index + 1
            ? { mb: spacing }
            : { borderBottom: '1px' })}
        >
          {({ isExpanded }) => (
            <>
              <AccordionButton
                pt="24px"
                pb={isExpanded ? '8px' : '24px'}
                _focus={{ outline: 'none' }}
              >
                <Flex mr="16px">
                  <Image
                    alt="accordion"
                    src={
                      isExpanded
                        ? '/icons/accordion_close.svg'
                        : '/icons/accordion_open.svg'
                    }
                    width={ICON_SIZE}
                    height={ICON_SIZE}
                    layout="fixed"
                  />
                </Flex>
                <Text
                  noOfLines={2}
                  flex="1"
                  textAlign="left"
                  fontFamily="Proxima Nova Semibold"
                  fontSize={{ base: '18px', md: '20px' }}
                  lineHeight="30px"
                  color="brand.darkSlateBlue"
                >
                  {title}
                </Text>
              </AccordionButton>
              <AccordionPanel
                pt={0}
                pb="24px"
                color="brand.darkSlateBlue"
                fontSize={{ base: '14px', md: '16px' }}
                lineHeight="25px"
              >
                {typeof content === 'string' ? (
                  <Text mx={{ base: 2, md: 6 }}>{content}</Text>
                ) : (
                  <Box mx={{ base: 2, md: 6 }}>{content}</Box>
                )}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </ChakraAccordion>
  )
}
