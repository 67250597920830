import { CustomQueryBuilder } from 'api/models/queryBuilders/CustomQueryBuilder'
import type { Tender as TenderModel } from 'api/models/Tender'

export enum TenderStatus {
  Published = 'PUBLISHED',
  Closed = 'CLOSED',
  ClosedWithoutWinner = 'CLOSED_WITHOUT_WINNER',
  Awarded = 'AWARDED',
  Deleted = 'DELETED',
}

export enum TenderSupportsOption {
  Preferred = 'PREFERRED',
  Optional = 'OPTIONAL',
  NotInterested = 'NOT_INTERESTED',
}

export enum BidStatus {
  New = 'NEW',
  Won = 'WON',
  Lost = 'LOST',
  Deleted = 'DELETED',
}

export enum ReceivedTenderSupportStatus {
  NotProvided = 'NOT_PROVIDED',
  InProgress = 'IN_PROGRESS',
  Received = 'RECEIVED',
}

export enum FileContext {
  TenderDocument = 'TENDER_DOCUMENT',
  BidPosDocument = 'BID_POS_DOCUMENT',
  TenderSalesVolumeData = 'TENDER_SALES_VOLUME_DATA',
  UserAvatar = 'USER_AVATAR',
  BidDocument = 'BID_DOCUMENT',
  ProductAvatar = 'PRODUCT_AVATAR',
  ProductDocument = 'PRODUCT_DOCUMENT',
}

export enum FileParentType {
  Tender = 'Tender',
  Bid = 'Bid',
  TenderDraft = 'TenderDraft',
  ProductTemplate = 'ProductTemplate',
  PromoDeal = 'PromoDeal',
}

export type TenderModelWithTotalEstimatedVolume = TenderModel & {
  totalEstimatedVolume: string | undefined
}

export type TenderWithTotalEstimatedVolume = CustomQueryBuilder<
  TenderModelWithTotalEstimatedVolume,
  TenderModelWithTotalEstimatedVolume
>

export const DIRECT_SUPPLY_VALUE = 'Direct Supply (no wholesaler used)'

export enum UserStatus {
  Verified = 'VERIFIED',
  Blocked = 'BLOCKED',
}

export enum NotificationCategory {
  System = 'SYSTEM',
  Messages = 'MESSAGE',
}

export enum NotificationType {
  NewTender = 'NEW_TENDER',
  TenderClosing = 'TENDER_CLOSING',
  TenderEnded = 'TENDER_ENDED',
  TenderUpdated = 'TENDER_UPDATED',
  TenderCloseWithoutWinner = 'TENDER_CLOSE_WITHOUT_WINNER',
  ReTenderReminder = 'RE_TENDER_REMINDER',
  TenderEndedReminder = 'TENDER_ENDED_REMINDER',
  NewBid = 'NEW_BID',
  BidRated = 'BID_RATED',
  BidUpdated = 'BID_UPDATED',
  WinnerAnnouncement = 'WINNER_ANNOUNCEMENT',
  GeneralMessage = 'GENERAL_MESSAGE',
  SubscriptionDeleted = 'SUBSCRIPTION_DELETED',
  PaymentIntentFailed = 'PAYMENT_INTENT_FAILED',
  SubscriptionDeletedStandardTier = 'SUBSCRIPTION_DELETED_STANDARD_TIER',
  PaymentIntentFailedStandardTier = 'PAYMENT_INTENT_FAILED_STANDARD_TIER',
}

export enum Region {
  Australia = 'Australia',
  UnitedKingdom = 'UnitedKingdom',
  Spain = 'Spain',
}

export enum NotificationTargetType {
  Venue = 'VENUE',
  Brand = 'BRAND',
}

export enum StripePaymentMethodType {
  CreditCard = 'card',
  DirectDebit = 'bacs_debit',
  BankTransfer = 'sepa_debit',
}

export enum PromoDealStatus {
  Published = 'PUBLISHED',
  Disabled = 'DISABLED',
}
