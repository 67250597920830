import { Box, Flex } from '@chakra-ui/react'
import Image from 'next/image'

import { ConnectCard } from './ConnectCard'
import { DistributionCard } from './DistributionCard'
import { MatchCard } from './MatchCard'
import { ParamountLiqourComponentProps, ParamountLiqourPageType } from './types'

const CardData = {
  Match: {
    alt: 'Match',
    src: '/landingPage/paramountLiqourCards/match.png',
    card: (pageType: ParamountLiqourPageType) => (
      <MatchCard pageType={pageType} />
    ),
  },
  Connect: {
    alt: 'Connect',
    src: '/landingPage/paramountLiqourCards/connect.png',
    card: (pageType: ParamountLiqourPageType) => (
      <ConnectCard pageType={pageType} />
    ),
  },
  Distribution: {
    alt: 'Distribution',
    src: '/landingPage/paramountLiqourCards/distribution.png',
    card: (pageType: ParamountLiqourPageType) => (
      <DistributionCard pageType={pageType} />
    ),
  },
}

const CardDataKeys = Object.keys(CardData) as (keyof typeof CardData)[]

export const ParamountLiqourCards = ({
  pageType,
}: ParamountLiqourComponentProps) =>
  CardDataKeys.map((key) => (
    <Box
      key={key}
      flex="1 0 0"
      height="420px"
      bgColor="brand.secondary.ultramarine50"
      borderRadius="12px"
    >
      <Flex w="100%" justifyContent="center">
        <Image
          alt={CardData[key].alt}
          height={272}
          width={351}
          src={CardData[key].src}
        />
      </Flex>
      <Flex flexDir="column" gap="12px">
        {CardData[key].card(pageType)}
      </Flex>
    </Box>
  ))
