import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'

import ContactUsForm from './ContactUsForm'

interface ContactUsModalProps {
  isOpen: boolean
  onClose: () => void
}

const ContactUsModal = ({ isOpen, onClose }: ContactUsModalProps) => {
  const { t } = useTranslation('common')

  return (
    <Modal
      size="lg"
      blockScrollOnMount
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pt="40px" pb="0">
          {t('TRANSLATION_287')}
        </ModalHeader>
        <ModalCloseButton fontSize="20px" mt="30px" />
        <ModalBody pt="30px" pb="40px">
          <ContactUsForm />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ContactUsModal
