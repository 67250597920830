import {
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'
import { getYear } from 'date-fns'
import Image from 'next/legacy/image'
import useTranslation from 'next-translate/useTranslation'

import { Link } from 'components/common/Link'
import { NextLink } from 'components/common/NextLink'
import { SocialMedia, SocialMediaIcon } from 'components/common/SocialMediaIcon'
import { Arrow, Copyright } from 'components/icons'
import { EzTendaEmailAddress } from 'constants/email'
import { EztendaPhoneNumberMap } from 'constants/eztendaPhoneNumber'
import { PublicFileLinks } from 'constants/publicFiles'
import { Route } from 'constants/routes'
import { Region } from 'generated/generated-graphql'
import { useRegion } from 'providers/RegionProvider'

const ICON_SIZE = '32px'

interface FooterProps {
  isBrandPage: boolean
}

export const Footer = ({ isBrandPage }: FooterProps) => {
  const { region } = useRegion()

  const isUkRegion = region === Region.UnitedKingdom

  const emailAddress = EzTendaEmailAddress[region]

  const { t } = useTranslation('common')

  return (
    <Box w="full" backgroundColor="white">
      <Container maxW="1110px" mt={{ base: '64px', lg: '125px' }}>
        <Flex w="full" justifyContent="center">
          <Flex
            w={{ base: 'full', sm: '500px', lg: 'full' }}
            direction={{ base: 'column', lg: 'row' }}
            gridRowGap="44px"
            justifyContent="space-between"
          >
            <Flex
              w={{ base: 'full', md: '50%' }}
              direction="column"
              alignItems="start"
            >
              <Image
                alt="eztenda logo"
                width={145}
                height={35}
                src="/logo.svg"
                layout="intrinsic"
              />
              <Text
                maxW={{ base: 'auto', md: '312px' }}
                fontSize="16px"
                lineHeight="25px"
                mt="13px"
              >
                {t('VENUE_PAGE_TEXT5')}
              </Text>
              <HStack
                my={{ base: '32px', md: '28px' }}
                w={{ base: 'full', md: 'auto' }}
                justifyContent={{ base: 'center', md: 'start' }}
                alignItems="center"
              >
                {isUkRegion && (
                  <Box
                    w={{ base: '60px', md: '69px' }}
                    h={{ base: '60px', md: '69px' }}
                    display="block"
                  >
                    <Image
                      alt="tech awards badge"
                      width={69}
                      height={69}
                      src="/landingPage/tech_awards_badge.png"
                      layout="responsive"
                    />
                  </Box>
                )}

                <Box
                  w={{ base: '60px', md: '69px' }}
                  h={{ base: '60px', md: '69px' }}
                  display="block"
                >
                  <Image
                    alt="food and drink awards badge"
                    width={69}
                    height={69}
                    src="/landingPage/food_and_drink_awards_badge.png"
                    layout="responsive"
                  />
                </Box>
                {isUkRegion && (
                  <Box w="56px" h="44px" display="block">
                    <Image
                      alt="uk dev awards badge"
                      width={56}
                      height={44}
                      src="/landingPage/uk_dev_awards_badge.png"
                      layout="responsive"
                    />
                  </Box>
                )}

                <Flex
                  w={{ base: '60px', md: '69px' }}
                  h={{ base: '60px', md: '69px' }}
                  justify="center"
                  align="center"
                >
                  <Box w="44px" h="44px" display="block">
                    <Image
                      alt="cyber essentials badge"
                      width={44}
                      height={44}
                      src="/landingPage/cyber_essentials_badge.png"
                      layout="responsive"
                    />
                  </Box>
                </Flex>
                {isUkRegion && (
                  <Flex
                    w={{ base: '60px', md: '69px' }}
                    h={{ base: '60px', md: '69px' }}
                    justify="center"
                    align="center"
                  >
                    <Box w="63px" h="35px" display="block">
                      <Image
                        alt="uk hospitality badge"
                        width={63}
                        height={35}
                        src="/landingPage/uk_hospitality_badge.png"
                        layout="responsive"
                      />
                    </Box>
                  </Flex>
                )}
              </HStack>
              <Flex
                w="full"
                direction={{ base: 'column', sm: 'row', md: 'column' }}
                justifyContent="space-between"
              >
                <Link
                  href={
                    isBrandPage
                      ? PublicFileLinks.brandTermsAndConditions[region]!
                      : PublicFileLinks.venueTermsAndConditions[region]!
                  }
                  mr={{ base: '26px', md: 0 }}
                  mb={{ base: 0, md: '16px' }}
                  fontFamily="Proxima Nova SemiBold"
                  lineHeight="22px"
                  color="brand.purpleNavy"
                  textDecoration="none"
                  isOpenInNewTab
                >
                  <Arrow
                    width="6px"
                    height="12px"
                    mr={4}
                    color="brand.ultramarine"
                  />
                  {isBrandPage
                    ? `${t('COMMON_FOOTER_TERMS')} (${t('BRAND_PAGE_TEXT2')})`
                    : `${t('COMMON_FOOTER_TERMS')} (${t('BRAND_PAGE_TEXT1')})`}
                </Link>
                <Link
                  href={PublicFileLinks.privacyPolicy[region]!}
                  fontFamily="Proxima Nova SemiBold"
                  lineHeight="22px"
                  color="brand.purpleNavy"
                  textDecoration="none"
                  isOpenInNewTab
                >
                  <Arrow
                    width="6px"
                    height="12px"
                    mr={4}
                    color="brand.ultramarine"
                  />
                  {t('COMMON_FOOTER_POLICY')}
                </Link>
              </Flex>
            </Flex>

            <Flex
              gridColumnGap="75px"
              gridRowGap="44px"
              direction={{ base: 'column', sm: 'row', md: 'row' }}
              justifyContent="space-between"
            >
              <Flex direction="column" align="start" minW="fit-content">
                <Text
                  mb="32px"
                  fontFamily="Proxima Nova SemiBold"
                  fontSize="20px"
                  lineHeight="24px"
                >
                  {t('COMMON_FOOTER_LINKS')}
                </Text>
                <VStack spacing="16px">
                  <NextLink
                    href={Route.VenueLandingPage()}
                    fontSize="16px"
                    lineHeight="25px"
                  >
                    {t('VENUE_PAGE_TEXT1')}
                  </NextLink>
                  <NextLink
                    href={Route.BrandLandingPage()}
                    fontSize="16px"
                    lineHeight="25px"
                  >
                    {t('VENUE_PAGE_TEXT2')}
                  </NextLink>
                </VStack>
              </Flex>
              <Flex direction="column" minW="fit-content">
                <Text
                  mb="32px"
                  fontFamily="Proxima Nova SemiBold"
                  fontSize="20px"
                  lineHeight="24px"
                >
                  {t('COMMON_FOOTER_FOLLOW')}
                </Text>
                <VStack spacing="16px" alignItems="start">
                  {/* <SocialMediaIcon
                    platform={SocialMedia.Twitter}
                    iconSize={ICON_SIZE}
                    label="Twitter"
                  /> */}
                  <SocialMediaIcon
                    platform={SocialMedia.LinkedIn}
                    iconSize={ICON_SIZE}
                    label="Linkedin"
                  />
                  {/* TODO uncomment when we will have FB handle */}
                  {/* <SocialMediaIcon
                  platform={SocialMedia.Facebook}
                  iconSize={ICON_SIZE}
                  label="Facebook"
                /> */}
                  {isUkRegion && (
                    <SocialMediaIcon
                      platform={SocialMedia.Instagram}
                      iconSize={ICON_SIZE}
                      label="Instagram"
                    />
                  )}
                </VStack>
              </Flex>
              <Flex direction="column" minW="fit-content" maxW="320px">
                <Text
                  mb="32px"
                  fontFamily="Proxima Nova SemiBold"
                  fontSize="20px"
                  lineHeight="24px"
                >
                  {t('BRAND_PROFILE_SUBSCRIPTION_CONTACT')}
                </Text>
                <VStack
                  fontSize="16px"
                  lineHeight="25px"
                  spacing="16px"
                  alignItems="start"
                >
                  <Text>{emailAddress}</Text>
                  <Text>{EztendaPhoneNumberMap[region]}</Text>
                </VStack>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Divider mt="80px" />
        <Flex
          w="full"
          my="24px"
          justifyContent="center"
          direction={{ base: 'column', lg: 'row' }}
        >
          <Spacer />
          <HStack flexGrow={1} justifyContent="center" spacing={0.5}>
            <Text>{t('TRANSLATION_315')}</Text>
            <Copyright width="24px" height="24px" color="brand.americanBlue" />
            <Text>
              {getYear(new Date())} {t('TRANSLATION_316')}
            </Text>
          </HStack>
          {isUkRegion && (
            <Text
              mt={{ base: '24px', lg: 0 }}
              mb={{ base: '41px', lg: 0 }}
              textAlign={{ base: 'center', lg: 'end' }}
              fontSize="16px"
              lineHeight="25px"
              color="brand.wildBlueYonder"
            >
              AWRS Number: XJAW 000 0011 7485
            </Text>
          )}
        </Flex>
      </Container>
    </Box>
  )
}
