import { Region } from 'api/generated/resolvers-types'

export const UNITED_KINGDOM_CURRENCY = '£'
export const AUSTRALIA_CURRENCY = 'AU$'
export const EURO_CURRENCY = '€'

export const CurrencyMapper: Record<Region, string> = {
  [Region.UnitedKingdom]: UNITED_KINGDOM_CURRENCY,
  [Region.Australia]: AUSTRALIA_CURRENCY,
  [Region.Spain]: EURO_CURRENCY,
}
