import { useCallback, useState } from 'react'

import {
  Box,
  BoxProps,
  Center,
  Container,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react'
import {
  AnimatePresence,
  LayoutGroup,
  motion,
  PanInfo,
  useMotionValue,
  useTransform,
} from 'framer-motion'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

import { Arrow } from 'components/common/Carousel/Arrow'
import { GalleryButton } from 'components/common/Gallery/GalleryButton'
import { Bottle, Cube, MessageBubbles, StarBold } from 'components/icons'

import { TranslateFunction } from '../../../next-translate.d'

const ICON_SIZE = '20px'

const getGalleryItems = (t: TranslateFunction<'common'>) => [
  {
    src: '/landingPage/snapshots/tender_detail_example.png',
    title: t('VENUE_PAGE_TEXT10'),
    highlight: t('VENUE_PAGE_TEXT11'),
    icon: <Cube w={ICON_SIZE} h={ICON_SIZE} />,
    width: 607,
    height: 280,
  },
  {
    src: '/landingPage/snapshots/messages_example.png',
    title: t('VENUE_PAGE_TEXT12'),
    highlight: t('VENUE_PAGE_TEXT13'),
    icon: <MessageBubbles w={ICON_SIZE} h={ICON_SIZE} />,
    width: 571,
    height: 298,
  },
  {
    src: '/landingPage/snapshots/request_demo_example.png',
    title: t('VENUE_PAGE_TEXT14'),
    highlight: t('VENUE_PAGE_TEXT15'),
    icon: <Bottle w={ICON_SIZE} h={ICON_SIZE} />,
    width: 538,
    height: 334,
  },
  {
    src: '/landingPage/snapshots/bids_table_example.png',
    title: t('VENUE_PAGE_TEXT16'),
    highlight: t('VENUE_PAGE_TEXT17'),
    icon: <StarBold w={ICON_SIZE} h={ICON_SIZE} />,
    width: 601,
    height: 354,
  },
]

interface GalleryContainerProps extends BoxProps {
  isBrandPage: boolean
}

const GallerySectionTest = ({ ...rest }: GalleryContainerProps) => {
  const { t } = useTranslation('common')
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const isOnMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: false,
    lg: false,
  })
  const dragX = useMotionValue(0)

  const galleryItems = getGalleryItems(t)

  const handlePrev = useCallback(() => {
    setActiveItemIndex(
      (activeItemIndex - 1 + galleryItems.length) % galleryItems.length
    )
  }, [galleryItems.length, activeItemIndex])

  const handleNext = useCallback(() => {
    setActiveItemIndex((activeItemIndex + 1) % galleryItems.length)
  }, [galleryItems.length, activeItemIndex])

  const handleDragEnd = useCallback(
    (_: unknown, dragProps: PanInfo) => {
      const { offset, velocity } = dragProps
      const swipeThreshold = 100
      if (offset.x > swipeThreshold || velocity.x > 500) {
        handlePrev()
      } else if (offset.x < -swipeThreshold || velocity.x < -500) {
        handleNext()
      }
    },
    [handleNext, handlePrev]
  )

  const activeImage = galleryItems[activeItemIndex]
  const xRange = [-100 * galleryItems.length, 100 * galleryItems.length]
  const transformX = useTransform(dragX, xRange, [-100, 100])

  return (
    <Box w="full" bgGradient="linear(to-b, #F7F7FB, white)" {...rest}>
      <Container maxW="1100px" p={0} centerContent>
        <Flex
          w="full"
          mt={{ base: '26px', md: '80px' }}
          direction={{ base: 'column', lg: 'row' }}
        >
          <Flex
            bg="brand.ghostWhite"
            borderColor="brand.mistyGray"
            borderWidth="1px"
            borderRadius="6px"
            boxShadow="medium"
            w="full"
            h={{ base: '358px', lg: '420px' }}
            maxW={{ base: 'full', lg: '645px' }}
            justify="center"
            align="center"
            mr={{ base: 0, lg: '40px' }}
            mb={{ base: '22px', md: '16px', lg: 0 }}
          >
            <Center w="full">
              <AnimatePresence mode="wait">
                <motion.div
                  key={activeItemIndex}
                  animate={{ opacity: 1, y: 0 }}
                  initial={{ opacity: 0, y: 20 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.2 }}
                >
                  <Box display="block">
                    {activeImage && (
                      <Image
                        alt="gallery image"
                        src={activeImage.src}
                        layout="intrinsic"
                        width={activeImage.width}
                        height={activeImage.height}
                      />
                    )}
                  </Box>
                </motion.div>
              </AnimatePresence>
            </Center>
          </Flex>

          {isOnMobile ? (
            <Box w="full" position="relative" px="8px">
              <motion.div
                style={{
                  x: transformX,
                }}
                drag="x"
                dragConstraints={{ left: 0, right: 0 }}
                dragElastic={0.5}
                onDragEnd={handleDragEnd}
                dragMomentum={false}
                dragTransition={{ bounceStiffness: 300, bounceDamping: 30 }}
              >
                <AnimatePresence initial={false} mode="wait">
                  <motion.div
                    key={activeItemIndex}
                    initial={{ opacity: 1 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    {
                      galleryItems.map(({ title, highlight, icon }, index) => (
                        <GalleryButton
                          key={title}
                          title={title}
                          highlight={highlight}
                          icon={icon}
                          isSelected={index === activeItemIndex}
                          onClick={() => {
                            setActiveItemIndex(index)
                          }}
                        />
                      ))[activeItemIndex]
                    }
                  </motion.div>
                </AnimatePresence>
              </motion.div>

              <Flex position="absolute" top="50%" left="0">
                <Arrow isLeft onClick={handlePrev} isOnMobile={isOnMobile} />
              </Flex>

              <Flex position="absolute" top="50%" right="0">
                <Arrow onClick={handleNext} isOnMobile={isOnMobile} />
              </Flex>
            </Box>
          ) : (
            <Flex>
              <LayoutGroup>
                <Flex
                  w={{ base: 'full', lg: '410px' }}
                  h="full"
                  direction={{ base: 'row', lg: 'column' }}
                  gridColumnGap="8px"
                  gridRowGap={{ base: '12px', md: '12px' }}
                  wrap="wrap"
                  justifyContent="center"
                >
                  {galleryItems.map(({ title, highlight, icon }, index) => (
                    <GalleryButton
                      key={title}
                      title={title}
                      highlight={highlight}
                      icon={icon}
                      isSelected={index === activeItemIndex}
                      onClick={() => {
                        setActiveItemIndex(index)
                      }}
                    />
                  ))}
                </Flex>
              </LayoutGroup>
            </Flex>
          )}
        </Flex>
      </Container>
    </Box>
  )
}

export default GallerySectionTest
