import { Box, Container, Flex, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'

import { Carousel } from 'components/common/Carousel/Carousel'

import TestimonialCard from './TestimonialCard'
import { TranslateFunction } from '../../../../next-translate.d'

interface TestimonialSectionProps {
  isBrandPage: boolean
}

const getTestimonials = (t: TranslateFunction<'common'>) => ({
  venue: [
    [
      {
        companyLogo: '/landingPage/testimonials/monkey_brew_thumbnail.png',
        companyLogoDimensions: { w: 48, h: 48 },
        testimonial: `${t('VENUE_PAGE_TEXT25')}”
    `,
        ownerImage: '/landingPage/testimonials/will_bradshaw_thumbnail.png',
        ownerName: 'Will Bradshaw',
        note: t('VENUE_PAGE_TEXT26'),
      },
      {
        companyLogo: '/landingPage/testimonials/randalls_thumbnail.png',
        companyLogoDimensions: { w: 48, h: 40 },
        testimonial: `${t('VENUE_PAGE_TEXT27')}”

    `,
        ownerImage: '/landingPage/testimonials/jack_greenall_thumbnail.png',
        ownerName: 'Jack Greenall',
        note: t('VENUE_PAGE_TEXT28'),
      },
    ],
  ],
  brand: [
    [
      {
        companyLogo: '/landingPage/testimonials/innovatus_drinks_thumbnail.png',
        companyLogoDimensions: { w: 131, h: 39 },
        testimonial: `${t('BRAND_PAGE_TEXT17')}`,
        ownerImage: '/landingPage/testimonials/katie_herbert_thumbnail.png',
        ownerName: 'Katie Herbert',
        note: t('BRAND_PAGE_TEXT18'),
      },
    ],
  ],
})

const TestimonialSection = ({ isBrandPage }: TestimonialSectionProps) => {
  const { t } = useTranslation('common')

  const testimonials = getTestimonials(t)

  return (
    <Box w="full">
      <Container
        pt={{ base: '50px', md: '106px' }}
        pb={{ base: '64px', md: '90px' }}
        maxW="1100px"
        position="relative"
        zIndex={1}
      >
        <Flex mb="40px" justifyContent="center">
          <Text
            fontFamily="Cocogoose Pro"
            fontSize={{ base: '24px', md: '32px' }}
            lineHeight={{ base: '30px', md: '41px' }}
            color="brand.ultramarine"
          >
            {t('VENUE_PAGE_TEXT24')}
          </Text>
        </Flex>
        {testimonials[isBrandPage ? 'brand' : 'venue'].length > 2 ? (
          <Carousel>
            {testimonials[isBrandPage ? 'brand' : 'venue'].map(
              (testimonialsGroup) => (
                // eslint-disable-next-line react/jsx-key
                <Flex
                  justifyContent={
                    testimonialsGroup.length === 1 ? 'center' : 'space-between'
                  }
                  direction={{ base: 'column', lg: 'row' }}
                  gridRowGap="16px"
                >
                  {testimonialsGroup.map(
                    ({
                      companyLogo,
                      testimonial,
                      companyLogoDimensions,
                      ownerImage,
                      ownerName,
                      note,
                    }) => (
                      <TestimonialCard
                        key={ownerName}
                        companyLogo={companyLogo}
                        companyLogoDimensions={companyLogoDimensions}
                        testimonial={testimonial}
                        ownerImage={ownerImage}
                        ownerName={ownerName}
                        note={note}
                      />
                    )
                  )}
                </Flex>
              )
            )}
          </Carousel>
        ) : (
          <Flex
            justifyContent={
              testimonials[isBrandPage ? 'brand' : 'venue'][0]?.length === 1
                ? 'center'
                : 'space-between'
            }
            direction={{ base: 'column', lg: 'row' }}
            gridRowGap="16px"
          >
            {testimonials[isBrandPage ? 'brand' : 'venue'][0]?.map(
              ({
                companyLogo,
                testimonial,
                companyLogoDimensions,
                ownerImage,
                ownerName,
                note,
              }) => (
                <TestimonialCard
                  key={ownerName}
                  companyLogo={companyLogo}
                  companyLogoDimensions={companyLogoDimensions}
                  testimonial={testimonial}
                  ownerImage={ownerImage}
                  ownerName={ownerName}
                  note={note}
                />
              )
            )}
          </Flex>
        )}
      </Container>
    </Box>
  )
}

export default TestimonialSection
