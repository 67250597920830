import { Box, Flex, HStack, Text } from '@chakra-ui/react'
import Autoplay from 'embla-carousel-autoplay'
import useEmblaCarousel from 'embla-carousel-react'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

import { useIsOnMobile } from 'hooks/useIsOnMobile'

const LogoSources = [
  '/landingPage/sliderLogos/stonegate_group_logo.png',
  '/landingPage/sliderLogos/spar_logo.png',
  '/landingPage/sliderLogos/simmons_logo.png',
  '/landingPage/sliderLogos/cc_logo.png',
  '/landingPage/sliderLogos/the_pig_logo.png',
  '/landingPage/sliderLogos/blacklock_logo.png',
  '/landingPage/sliderLogos/the_albion_logo.png',
  '/landingPage/sliderLogos/vagabond_logo.png',
  '/landingPage/sliderLogos/bromley_cc_hotel_logo.png',
  '/landingPage/sliderLogos/manicomio_logo.png',
  '/landingPage/sliderLogos/stonegate_group_logo.png',
  '/landingPage/sliderLogos/spar_logo.png',
  '/landingPage/sliderLogos/simmons_logo.png',
  '/landingPage/sliderLogos/cc_logo.png',
  '/landingPage/sliderLogos/the_pig_logo.png',
  '/landingPage/sliderLogos/blacklock_logo.png',
  '/landingPage/sliderLogos/the_albion_logo.png',
  '/landingPage/sliderLogos/vagabond_logo.png',
  '/landingPage/sliderLogos/bromley_cc_hotel_logo.png',
  '/landingPage/sliderLogos/manicomio_logo.png',
]

export const PartnersSlider = () => {
  const { t } = useTranslation('common')
  const isOnMobile = useIsOnMobile()
  const [emblaRef] = useEmblaCarousel({ loop: true, startIndex: 5 }, [
    Autoplay({ delay: 2000 }),
  ])
  return (
    <Flex direction="column" w="full">
      <Text
        w="full"
        align="center"
        fontFamily="Proxima Nova Semibold"
        fontSize="18px"
        color="brand.darkGray"
      >
        {t('BRAND_PAGE_TEXT10')}
      </Text>
      <Box overflow="hidden" mt="24px" ref={emblaRef}>
        <HStack h="auto">
          {LogoSources.map((src) => (
            <Image
              key={src}
              src={src}
              width={isOnMobile ? 120 : 180}
              height={isOnMobile ? 34 : 60}
              alt=""
            />
          ))}
        </HStack>
      </Box>
    </Flex>
  )
}
