import { useEffect, useState } from 'react'

import { Box, Container, Flex, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'

import { Region } from 'api/constants/models'
import { Accordion } from 'components/common/Accordion'
import { EzTendaEmailAddress } from 'constants/email'
import { useRegion } from 'providers/RegionProvider'

import { getAustraliaBrandQuestions } from './AustralianBrandQuestions'
import { getAustraliaVenueQuestions } from './AustralianVenueQuestions'
import { getBrandQuestions } from './BrandQuestinons'
import { getVenueQuestions } from './VenueQuestions'

interface FaqSectionProps {
  isBrandPage: boolean
}

const FaqSection = ({ isBrandPage }: FaqSectionProps) => {
  const { t } = useTranslation('common')
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const { region } = useRegion()
  const emailAddress = EzTendaEmailAddress[region] ?? ''

  const faqData = isBrandPage
    ? getBrandQuestions(region, t, emailAddress)
    : getVenueQuestions(region, t, emailAddress)
  const australiaFaqData = isBrandPage
    ? getAustraliaBrandQuestions(t, emailAddress)
    : getAustraliaVenueQuestions(t, emailAddress)

  useEffect(() => {
    if (activeTabIndex > faqData.length - 1) {
      setActiveTabIndex(0)
    }
  }, [activeTabIndex, faqData.length])

  return (
    <Box w="full" bg="brand.fogGray">
      <Container maxW="1040px">
        <Flex direction="column" align="center">
          <Text
            mt={{ base: '64px', md: '80px' }}
            mb={{ base: '40px', md: '48px' }}
            fontFamily="Cocogoose Pro"
            fontSize="32px"
            lineHeight="42px"
            color="brand.ultramarine"
            textAlign="center"
          >
            {t('TRANSLATION_295')}
          </Text>
          <Flex
            w="full"
            direction={{ base: 'column', md: 'row' }}
            mb={{ base: '64px', md: '105px' }}
          >
            <Flex
              direction={{ base: 'row', md: 'column' }}
              overflow={{ base: 'hidden', md: 'visible' }}
              mb={{ base: '16px', md: 0 }}
              mr={{ base: 0, md: '60px' }}
            >
              <Text
                fontFamily="Proxima Nova SemiBold"
                fontSize="16px"
                lineHeight="25px"
                color="brand.darkBlueGray"
                minW="120px"
                mr={{ base: '16px', md: 0 }}
                display={{ base: 'none', sm: 'block' }}
              >
                {t('TRANSLATION_296')}
              </Text>
              <Flex
                direction={{ base: 'row', md: 'column' }}
                align="start"
                overflowX={{ base: 'scroll', md: 'auto' }}
                whiteSpace={{ base: 'normal', md: 'nowrap' }}
                minW="fit-content"
              >
                {(region === Region.Australia ? australiaFaqData : faqData).map(
                  ({ tabName }, index) => (
                    <Text
                      key={tabName}
                      fontSize="16px"
                      color="brand.darkBlueGray"
                      onClick={() => {
                        setActiveTabIndex(index)
                      }}
                      {...(activeTabIndex === index && {
                        fontFamily: 'Proxima Nova Semibold',
                        color: 'brand.ultramarine',
                        textDecoration: 'underline',
                      })}
                      cursor="pointer"
                      minW="fit-content"
                      mr={{ base: '16px', md: 0 }}
                      mt={{ base: 0, md: '16px' }}
                    >
                      {tabName}
                    </Text>
                  )
                )}
              </Flex>
            </Flex>

            <Flex w="full">
              {(region === Region.Australia ? australiaFaqData : faqData).map(
                (data, index) => (
                  <Accordion
                    key={data.tabName}
                    questions={data.questions}
                    isActive={index === activeTabIndex}
                    spacing={{ base: '16px', md: '0px' }}
                  />
                )
              )}
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

export default FaqSection
