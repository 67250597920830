import { Link, ListItem, Text, UnorderedList } from '@chakra-ui/react'

import { PublicFileLinks } from 'constants/publicFiles'
import { Region } from 'generated/generated-graphql'

import { TabsTitles } from './TabTitles'
import { TranslateFunction } from '../../../../next-translate'

export const getAustraliaVenueQuestions = (
  t: TranslateFunction<'common'>,
  emailAddress: string
) => {
  const mailTo = `mailto:${emailAddress}`

  return [
    {
      tabName: t(TabsTitles.About),
      questions: [
        {
          title: t('VENUE_FAQ_ABOUT_Q1'),
          content: (
            <UnorderedList>
              <ListItem>{t('VENUE_FAQ_ABOUT_A11')}</ListItem>
              <ListItem>{t('VENUE_FAQ_ABOUT_A12')}</ListItem>
            </UnorderedList>
          ),
        },
        {
          title: t('VENUE_FAQ_ABOUT_Q2'),
          content: (
            <UnorderedList>
              <ListItem>{t('VENUE_FAQ_ABOUT_A21')}</ListItem>
              <ListItem>{t('VENUE_FAQ_ABOUT_A22')}</ListItem>
              <ListItem>{t('VENUE_FAQ_ABOUT_A23')}</ListItem>
            </UnorderedList>
          ),
        },
        {
          title: t('VENUE_FAQ_ABOUT_Q3'),
          content: t('VENUE_FAQ_ABOUT_A3'),
        },
        {
          title: t('VENUE_FAQ_ABOUT_Q4'),
          content: t('VENUE_FAQ_ABOUT_A4'),
        },
        {
          title: t('VENUE_FAQ_ABOUT_Q5'),
          content: t('VENUE_FAQ_ABOUT_A5'),
        },
        {
          title: t('VENUE_FAQ_ABOUT_Q6'),
          content: (
            <Text>
              {t('T_AND_C')}{' '}
              <Link
                color="brand.ultramarine"
                href={PublicFileLinks.venueTermsAndConditions[Region.Australia]}
              >
                here
              </Link>
              .
            </Text>
          ),
        },
      ],
    },
    {
      tabName: t(TabsTitles.PricingAndFees),
      questions: [
        {
          title: t('VENUE_FAQ_PRICING_Q1'),
          content: t('VENUE_FAQ_PRICING_A1'),
        },
        {
          title: t('VENUE_FAQ_PRICING_Q2'),
          content: t('VENUE_FAQ_PRICING_A2'),
        },
        {
          title: t('VENUE_FAQ_PRICING_Q3'),
          content: t('VENUE_FAQ_PRICING_A3'),
        },
        {
          title: t('VENUE_FAQ_PRICING_Q4'),
          content: t('VENUE_FAQ_PRICING_A4'),
        },
      ],
    },

    {
      tabName: t(TabsTitles.PlatformRules),
      questions: [
        {
          title: t('VENUE_FAQ_PLATFORM_Q1'),
          content: (
            <>
              {t('VENUE_FAQ_PLATFORM_A11')}
              <UnorderedList>
                <ListItem>{t('VENUE_FAQ_PLATFORM_A12')}</ListItem>
                <ListItem>{t('VENUE_FAQ_PLATFORM_A13')}</ListItem>
                <ListItem>{t('VENUE_FAQ_PLATFORM_A14')}</ListItem>
              </UnorderedList>
              {t('VENUE_FAQ_PLATFORM_A15')}
            </>
          ),
        },
        {
          title: t('VENUE_FAQ_PLATFORM_Q2'),
          content: t('VENUE_FAQ_PLATFORM_A2'),
        },
      ],
    },
    {
      tabName: t(TabsTitles.AccountUsers),
      questions: [
        {
          title: t('VENUE_FAQ_ACCOUNT_Q1'),
          content: t('VENUE_FAQ_ACCOUNT_A1'),
        },
      ],
    },

    {
      tabName: t(TabsTitles.BrandSupport),
      questions: [
        {
          title: t('VENUE_FAQ_SUPPORT_Q1'),
          content: t('VENUE_FAQ_SUPPORT_A1'),
        },
        {
          title: t('VENUE_FAQ_SUPPORT_Q2'),
          content: t('VENUE_FAQ_SUPPORT_A2'),
        },
        {
          title: t('VENUE_FAQ_SUPPORT_Q3'),
          content: t('VENUE_FAQ_SUPPORT_A3'),
        },
      ],
    },

    {
      tabName: t('TRANSLATION_82'),
      questions: [
        {
          title: t('VENUE_FAQ_TENDERS_Q1'),
          content: (
            <Text>
              {t('CONTACT_1')}{' '}
              <Link color="brand.ultramarine" href={mailTo}>
                {emailAddress}
              </Link>
              {t('CONTACT_2')}
            </Text>
          ),
        },
      ],
    },
    {
      tabName: t(TabsTitles.Communications),
      questions: [
        {
          title: t('VENUE_FAQ_COMM_Q1'),
          content: t('VENUE_FAQ_COMM_A1'),
        },
        {
          title: t('VENUE_FAQ_COMM_Q2'),
          content: t('VENUE_FAQ_COMM_A2'),
        },
        {
          title: t('VENUE_FAQ_COMM_Q3'),
          content: t('VENUE_FAQ_COMM_A3'),
        },
      ],
    },
    {
      tabName: t(TabsTitles.ListingAgreement),
      questions: [
        {
          title: t('VENUE_FAQ_LISTING_Q1'),
          content: (
            <UnorderedList>
              <ListItem>{t('VENUE_FAQ_LISTING_A11')}</ListItem>
              <ListItem>{t('VENUE_FAQ_LISTING_A12')}</ListItem>
            </UnorderedList>
          ),
        },
      ],
    },
  ]
}
