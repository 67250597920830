import { Region } from 'generated/generated-graphql'

export const PublicFileLinks: Record<
  | 'brandTermsAndConditions'
  | 'venueTermsAndConditions'
  | 'privacyPolicy'
  | 'privacyPolicySignup',
  Record<Region, string | undefined>
> = {
  brandTermsAndConditions: {
    [Region.UnitedKingdom]:
      'https://eztenda-public-files.s3.eu-west-2.amazonaws.com/tac_brands.pdf',
    [Region.Australia]:
      'https://eztenda-public-files.s3.eu-west-2.amazonaws.com/tac_brands_AU.pdf',
    [Region.Spain]:
      'https://eztenda-public-files.s3.eu-west-2.amazonaws.com/tac_brands_ES.pdf',
  },
  venueTermsAndConditions: {
    [Region.UnitedKingdom]:
      'https://eztenda-public-files.s3.eu-west-2.amazonaws.com/tac_venues.pdf',
    [Region.Australia]:
      'https://eztenda-public-files.s3.eu-west-2.amazonaws.com/tac_venues_AU.pdf',
    [Region.Spain]:
      'https://eztenda-public-files.s3.eu-west-2.amazonaws.com/tac_venues_ES.pdf',
  },
  privacyPolicy: {
    [Region.UnitedKingdom]:
      'https://eztenda-public-files.s3.eu-west-2.amazonaws.com/privacy_policy.pdf',
    [Region.Australia]:
      'https://eztenda-public-files.s3.eu-west-2.amazonaws.com/privacy_policy_AU.pdf',
    [Region.Spain]:
      'https://eztenda-public-files.s3.eu-west-2.amazonaws.com/privacy_policy_ES.pdf',
  },
  privacyPolicySignup: {
    [Region.UnitedKingdom]:
      'https://eztenda-public-files.s3.eu-west-2.amazonaws.com/privacy_policy_signup.pdf',
    [Region.Australia]:
      'https://eztenda-public-files.s3.eu-west-2.amazonaws.com/privacy_policy_signup_AU.pdf',
    [Region.Spain]:
      'https://eztenda-public-files.s3.eu-west-2.amazonaws.com/privacy_policy_signup_ES.pdf',
  },
}
