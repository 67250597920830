import { useMemo } from 'react'

import {
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react'
import Image from 'next/legacy/image'
import useTranslation from 'next-translate/useTranslation'

import { AustraliaFlag, SpainFlag, UnitedKingdomFlag } from 'components/icons'
import { Region } from 'generated/generated-graphql'
import { Feature, useIsFeatureEnabled } from 'hooks/useIsFeatureEnabled'
import { useRegion } from 'providers/RegionProvider'

interface RegionSelectorProps {
  hasLabel?: boolean
  hasPopoverTitle?: boolean
  onRegionChange?: () => void
}

export const RegionSelector = ({
  hasLabel,
  hasPopoverTitle,
  onRegionChange,
}: RegionSelectorProps) => {
  const { t } = useTranslation('common')
  const { changeRegion, region: currentRegion } = useRegion()
  const isSpainEnabled = useIsFeatureEnabled(Feature.Spain)

  const regionOptions = useMemo(
    () => [
      {
        label: 'United Kingdom',
        region: Region.UnitedKingdom,
        flagIcon: <UnitedKingdomFlag w="30px" h="22px" />,
      },
      {
        label: 'Australia',
        region: Region.Australia,
        flagIcon: <AustraliaFlag w="30px" h="22px" />,
      },
      ...(isSpainEnabled
        ? [
            {
              label: 'Spain',
              region: Region.Spain,
              flagIcon: <SpainFlag w="30px" h="22px" />,
            },
          ]
        : []),
    ],
    [isSpainEnabled]
  )

  return (
    <Popover variant="regionSelect">
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <HStack spacing="8px" align="center" color="black" cursor="pointer">
              {
                regionOptions.find(({ region }) => currentRegion === region)
                  ?.flagIcon
              }
              {hasLabel && (
                <Text fontFamily="Proxima Nova Medium">
                  {
                    regionOptions.find(({ region }) => currentRegion === region)
                      ?.label
                  }
                </Text>
              )}
              <Image
                src="/icons/dropdown_arrow.svg"
                alt="dropdown arrow"
                width={12}
                height={5}
                layout="fixed"
              />
            </HStack>
          </PopoverTrigger>
          <PopoverContent w="fit-content" p={0}>
            <PopoverBody p="12px">
              <VStack spacing="8px" align="start">
                {hasPopoverTitle && (
                  <Text fontFamily="Proxima Nova Medium" px="8px" py="2px">
                    {t('TRANSLATION_23')}
                  </Text>
                )}
                <VStack spacing={0} align="start">
                  {regionOptions.map(({ label, flagIcon, region }) => (
                    <HStack
                      key={label}
                      w="full"
                      spacing="8px"
                      py="8px"
                      px="10px"
                      borderRadius="6px"
                      cursor="pointer"
                      _hover={{ bg: 'state.hoverLight' }}
                      {...(currentRegion === region && { bg: 'brand.fogGray' })}
                      onClick={() => {
                        changeRegion(region)
                        onRegionChange?.()
                        onClose()
                      }}
                    >
                      {flagIcon}
                      <Text>{label}</Text>
                    </HStack>
                  ))}
                </VStack>
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
