import {
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import Image from 'next/legacy/image'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { transparentize } from 'polished'

import { NextButton } from 'components/common/NextButton'
import { NextLink } from 'components/common/NextLink'
import { RegionSelector } from 'components/common/RegionSelector'
import { Route } from 'constants/routes'
import { useLoading } from 'providers/LoadingProvider'
import { Colors } from 'theme/constants'

export const NavBarMobileDrawer = () => {
  const { t } = useTranslation('common')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const router = useRouter()
  const { startLoading } = useLoading()

  return (
    <>
      <Center>
        <Button variant="ghost" onClick={onOpen}>
          <Image
            alt="hamburger menu"
            src="/icons/hamburger_menu.svg"
            width={16}
            height={30}
            layout="fixed"
          />
        </Button>
      </Center>
      <Drawer placement="top" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay
          backgroundColor={`${transparentize(0.5, Colors.brand.purpleNavy)}`}
        />
        <DrawerContent>
          <DrawerHeader pb="73px">
            <Image
              alt="logo"
              src="/logo.svg"
              width={145}
              height={29}
              layout="fixed"
            />
            <DrawerCloseButton top="17px" color="brand.ultramarine" />
          </DrawerHeader>
          <DrawerBody pt={0}>
            <VStack align="center" spacing="48px" mb="40px">
              <NextLink
                href={Route.VenueLandingPage()}
                mt="6px"
                fontSize="32"
                lineHeight="24px"
                color={
                  router.pathname === Route.VenueLandingPage()
                    ? 'brand.ultramarine'
                    : 'brand.americanBlue'
                }
                onClick={() => {
                  onClose()
                }}
              >
                {t('VENUE_PAGE_TEXT1')}
              </NextLink>
              <NextLink
                href={Route.BrandLandingPage()}
                mt="6px"
                fontSize="32"
                lineHeight="24px"
                color={
                  router.pathname === Route.BrandLandingPage()
                    ? 'brand.ultramarine'
                    : 'brand.americanBlue'
                }
                onClick={() => {
                  onClose()
                }}
              >
                {t('VENUE_PAGE_TEXT2')}
              </NextLink>
              <Flex w="full" align="center" justifyContent="center">
                <NextLink
                  w="109px"
                  mr="16px"
                  href={Route.SignIn()}
                  fontFamily="Proxima Nova Semibold"
                  fontSize="18px"
                  lineHeight="24px"
                  textAlign="center"
                >
                  {t('VENUE_PAGE_TEXT3')}
                </NextLink>
                <NextButton
                  w="109px"
                  href={Route.SignUp()}
                  fontFamily="Proxima Nova Semibold"
                  fontSize="18px"
                  lineHeight="24px"
                >
                  {t('VENUE_PAGE_TEXT4')}
                </NextButton>
              </Flex>
              <RegionSelector hasPopoverTitle onRegionChange={startLoading} />
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
