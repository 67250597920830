import { createIcon } from '@chakra-ui/react'

export const Play = createIcon({
  displayName: 'Play',
  viewBox: '0 0 12 12',
  path: (
    <path
      d="M11.3333 6.00086C11.3333 6.29985 11.008 6.51484 11.008 6.51484L1.87623 11.8177C1.21063 12.2266 0.666626 11.9236 0.666626 11.1487V0.852046C0.666626 0.0750733 1.21063 -0.225916 1.87729 0.182069L11.009 5.48688C11.008 5.48688 11.3333 5.70187 11.3333 6.00086Z"
      fill="#315CFD"
    />
  ),
})
