import { useMemo, useState } from 'react'

import { Box, Button, Flex, Grid, Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { NextButton } from 'components/common/NextButton'
import { FormCheckbox, FormDropdown, FormTextInput } from 'components/Inputs'
import { DropdownVariant } from 'components/Inputs/Dropdown/Dropdown'
import { TextInputVariant } from 'components/Inputs/TextInput/TextInput'
import { CurrencyMapper } from 'constants/currency'
import { ErrorMessage } from 'constants/messages'
import { PublicFileLinks } from 'constants/publicFiles'
import { Route } from 'constants/routes'
import { VenueCategoryTranslationsKeysMap } from 'constants/translationsMaps'
import {
  useSupportCalculationMutation,
  useVenueCategoriesQuery,
} from 'generated/generated-graphql'
import { useRegion } from 'providers/RegionProvider'

import { TranslateFunction } from '../../../../next-translate.d'

enum FieldName {
  VenueType = 'venueType',
  NumberOfVenues = 'numberOfVenues',
  TotalBeveragePurchases = 'totalBeveragePurchases',
  WorkEmail = 'workEmail',
  TermsAndConditions = 'termsAndConditions',
}

const CreateBrandSupportCalculatorSchema = (t: TranslateFunction<'common'>) =>
  yup.object({
    [FieldName.VenueType]: yup
      .object({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .defined()
      .label(t('VENUE_PAGE_TEXT31')),
    [FieldName.NumberOfVenues]: yup
      .number()
      .positive()
      .integer()
      .required()
      .label(t('VENUE_PAGE_TEXT39')),
    [FieldName.TotalBeveragePurchases]: yup
      .number()
      .positive()
      .integer()
      .required()
      .label(t('VENUE_PAGE_TEXT41')),
    [FieldName.WorkEmail]: yup
      .string()
      .email()
      .required()
      .label(t('VENUE_PAGE_TEXT43')),
    [FieldName.TermsAndConditions]: yup
      .boolean()
      .required(t(ErrorMessage.RequiredConsent))
      .oneOf([true], t(ErrorMessage.RequiredConsent)),
  })

type BrandSupportCalculatorSchemaType = yup.InferType<
  ReturnType<typeof CreateBrandSupportCalculatorSchema>
>

export const BrandSupportCalculatorSection = () => {
  const { t } = useTranslation('common')
  const [hasSubmittedForm, setHasSubmittedForm] = useState(false)
  const [supportCalculation, { loading: isSupportCalculationMutationLoading }] =
    useSupportCalculationMutation()
  const { region } = useRegion()
  const { data } = useVenueCategoriesQuery({ variables: { region } })

  const formMethods = useForm<BrandSupportCalculatorSchemaType>({
    resolver: yupResolver(CreateBrandSupportCalculatorSchema(t)),
    defaultValues: {
      [FieldName.VenueType]: undefined,
      [FieldName.NumberOfVenues]: undefined,
      [FieldName.TotalBeveragePurchases]: undefined,
      [FieldName.WorkEmail]: '',
    },
  })

  const {
    formState: { errors },
  } = formMethods

  const submit = async ({
    numberOfVenues,
    venueType,
    workEmail,
    totalBeveragePurchases,
  }: BrandSupportCalculatorSchemaType) => {
    const result = await supportCalculation({
      variables: {
        input: {
          numberOfVenues,
          venueType:
            VenueCategoryTranslationsKeysMap[region][venueType.label] ?? '',
          totalBeveragePurchases,
          email: workEmail,
          region,
        },
      },
    })
    if (result.data?.supportCalculator) {
      setHasSubmittedForm(true)
    }
  }

  const categoriesOptions = useMemo(
    () =>
      data?.venueCategories.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [data?.venueCategories]
  )

  return (
    <Flex
      id="brand-support-calculator"
      justifyContent="center"
      textAlign="center"
      backgroundImage="/slice.png"
      height="100%"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="center"
    >
      <FormProvider {...formMethods}>
        <Box mt="35px" mb="60px" maxW="700px" paddingX="15px">
          <Text color="white" fontWeight="300" fontSize="32px">
            {t('VENUE_PAGE_TEXT29')}
          </Text>
          {hasSubmittedForm ? (
            <>
              <Text color="white" fontSize="19px">
                {t('TRANSLATION_289')}
              </Text>
              <NextButton
                variant="orange"
                w="full"
                href={Route.SignUp()}
                mt="24px"
              >
                {t('TRANSLATION_290')}
              </NextButton>
              <Button
                mt="22px"
                fontWeight="600"
                variant="link"
                color="white"
                onClick={() => {
                  formMethods.reset()
                  setHasSubmittedForm(false)
                }}
              >
                {t('TRANSLATION_291')}
              </Button>
            </>
          ) : (
            <>
              <Text color="white" fontSize="19px">
                {t('VENUE_PAGE_TEXT30')}
              </Text>
              <Box maxW="500px" margin="0 auto">
                <Grid
                  py="30px"
                  templateColumns={{ sm: 'none', md: 'repeat(2, 2fr)' }}
                  rowGap="36px"
                  columnGap="40px"
                >
                  {/* WORKAROUND: the length check is a workaround for defaultValue, otherwise, it's not picked up */}
                  {categoriesOptions && categoriesOptions.length > 0 && (
                    <FormDropdown
                      styleVariant={DropdownVariant.White}
                      id={FieldName.VenueType}
                      label={t('VENUE_PAGE_TEXT31')}
                      options={categoriesOptions}
                      errorMessage={errors[FieldName.VenueType]?.value?.message}
                    />
                  )}
                  <FormTextInput
                    variant={TextInputVariant.White}
                    id={FieldName.NumberOfVenues}
                    type="number"
                    label={t('VENUE_PAGE_TEXT39')}
                    placeholder={t('EG_NUMBER', {
                      number: 6,
                    }).toLowerCase()}
                    errorMessage={errors[FieldName.NumberOfVenues]?.message}
                    stackProps={{ justifyContent: 'flex-start' }}
                  />
                  <FormTextInput
                    variant={TextInputVariant.White}
                    id={FieldName.TotalBeveragePurchases}
                    type="number"
                    label={t('VENUE_PAGE_TEXT41')}
                    placeholder={`${t('VENUE_PAGE_TEXT42')} ${
                      CurrencyMapper[region]
                    }`}
                    errorMessage={
                      errors[FieldName.TotalBeveragePurchases]?.message
                    }
                    stackProps={{ justifyContent: 'flex-start' }}
                  />
                  <FormTextInput
                    variant={TextInputVariant.White}
                    id={FieldName.WorkEmail}
                    label={t('VENUE_PAGE_TEXT43')}
                    placeholder={t('VENUE_PAGE_TEXT44')}
                    errorMessage={errors[FieldName.WorkEmail]?.message}
                    stackProps={{ justifyContent: 'flex-start' }}
                  />
                </Grid>
                <FormCheckbox
                  id={FieldName.TermsAndConditions}
                  backgroundColor="white"
                  description={
                    <Trans
                      i18nKey="common:VENUE_PAGE_TEXT45"
                      components={{
                        t: (
                          <Text
                            pl="12px"
                            fontSize="xm"
                            textAlign="left"
                            lineHeight="shorter"
                            fontFamily="Proxima Nova"
                            color="white"
                          />
                        ),
                        a1: (
                          // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'underline' }}
                            href={
                              PublicFileLinks.venueTermsAndConditions[region]
                            }
                          />
                        ),
                        a2: (
                          // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'underline' }}
                            href={PublicFileLinks.privacyPolicy[region]}
                          />
                        ),
                      }}
                    />
                  }
                  wrapperStyleProps={{
                    flexDirection: 'row',
                    maxWidth: '500px',
                    margin: '0 auto',
                  }}
                />
                {errors?.termsAndConditions?.message && (
                  <Text color="state.lightError" textAlign="left">
                    {errors?.termsAndConditions.message}
                  </Text>
                )}
                <Button
                  variant="orange"
                  w="full"
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={formMethods.handleSubmit(submit)}
                  isLoading={isSupportCalculationMutationLoading}
                  mt="24px"
                >
                  {t('VENUE_PAGE_TEXT46')}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </FormProvider>
    </Flex>
  )
}
