import { ReactNode } from 'react'

import { motion, MotionValue, PanInfo } from 'framer-motion'

interface SliderProps {
  draggableAxis: MotionValue<number>
  children: ReactNode
  onDragEnd: (e: Event, dragProps: PanInfo) => void
}

export const Slider = ({ draggableAxis, onDragEnd, children }: SliderProps) => (
  <motion.div
    style={{
      width: '100%',
      height: '100%',
      display: 'inline-block',
      flex: 'none',
      x: draggableAxis,
    }}
    drag="x"
    dragElastic={0.3}
    onDragEnd={onDragEnd}
  >
    {children}
  </motion.div>
)
