import { Box, Button, VStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import useTranslation from 'next-translate/useTranslation'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { FormTextarea, FormTextInput } from 'components/Inputs'
import { EmailType, useRequestEmailMutation } from 'generated/generated-graphql'
import { useRegion } from 'providers/RegionProvider'
import { useSuccessToast } from 'utils/toast'

import { TranslateFunction } from '../../../../next-translate'

enum FieldName {
  Name = 'name',
  Email = 'email',
  Message = 'message',
}

enum FieldPlaceholder {
  Name = 'Type your Name',
}

const CreateContactUsFormValidationSchema = (t: TranslateFunction<'common'>) =>
  yup.object({
    [FieldName.Name]: yup.string().required(),
    [FieldName.Message]: yup.string().required(),
    [FieldName.Email]: yup.string().email(t('WRONG_EMAIL_FORMAT')).required(),
  })

type FormValues = yup.InferType<
  ReturnType<typeof CreateContactUsFormValidationSchema>
>

const ContactUsForm = () => {
  const { t } = useTranslation('common')
  const methods = useForm<FormValues>({
    defaultValues: {
      [FieldName.Name]: '',
      [FieldName.Email]: '',
      [FieldName.Message]: '',
    },
    resolver: yupResolver(CreateContactUsFormValidationSchema(t)),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })
  const { region } = useRegion()
  const showSuccessToast = useSuccessToast()
  const [requestContact, { loading: isRequestContactLoading }] =
    useRequestEmailMutation()

  const submit = async (data: FormValues) => {
    const requesterEmail = data.email
    const requestDemoResult = await requestContact({
      variables: {
        input: {
          emailType: EmailType.GetInTouch,
          requesterEmail,
          region,
          requestEmailArguments: [
            { key: 'requesterEmail', value: requesterEmail },
            { key: 'getintouchName', value: data.name },
            { key: 'getintouchMessage', value: data.message },
          ],
        },
      },
    })

    if (!requestDemoResult.errors) {
      showSuccessToast({ description: t('TRANSLATION_286') })
      methods.reset({})
    }
  }

  return (
    <Box>
      <FormProvider {...methods}>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={methods.handleSubmit(submit)}>
          <VStack spacing="32px">
            <FormTextInput
              id={FieldName.Name}
              label={t('TRANSLATION_292')}
              placeholder={FieldPlaceholder.Name}
              backgroundColor="white"
            />
            <FormTextInput
              id={FieldName.Email}
              label={t('EMAIL')}
              placeholder={t('EMAIL_PLACEHOLDER')}
              backgroundColor="white"
            />
            <FormTextarea
              id={FieldName.Message}
              label={t('Message')}
              placeholder={t('TRANSLATION_293')}
              height="132px"
              backgroundColor="white"
            />
          </VStack>
          <Button
            type="submit"
            h="56px"
            mt="40px"
            w="full"
            isLoading={isRequestContactLoading}
          >
            {t('BRAND_PROFILE_SUBSCRIPTION_GETINTOUCH')}
          </Button>
        </form>
      </FormProvider>
    </Box>
  )
}

export default ContactUsForm
