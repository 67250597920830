export enum TabsTitles {
  About = 'VENUE_FAQ_ABOUT_HEADER',
  PricingAndFees = 'VENUE_FAQ_PRICING_HEADER',
  PlatformRules = 'VENUE_FAQ_PLATFORM_HEADER',
  AccountUsers = 'VENUE_FAQ_ACCOUNT_HEADER',
  BrandSupport = 'VENUE_FAQ_SUPPORT_HEADER',
  Bids = 'TRANSLATION_164',
  ListingAgreement = 'VENUE_FAQ_LISTING_HEADER',
  Communications = 'VENUE_FAQ_COMM_HEADER',
}
