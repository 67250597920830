import {
  Box,
  BoxProps,
  Button,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'

import ContactUsModal from './ContactUs/ContactUsModal'

interface RequestDemoSectionProps extends BoxProps {
  isBrandPage: boolean
}

const RequestDemoSection = ({
  isBrandPage,
  ...rest
}: RequestDemoSectionProps) => {
  const { t } = useTranslation('common')
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box
      position="relative"
      backgroundImage="/landingPage/request_demo_gradient.png"
      backgroundSize="cover"
      backgroundPosition="center"
      w="full"
      {...rest}
    >
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        h="284px"
      >
        <Text
          maxW="500px"
          fontFamily="Cocogoose Pro"
          fontSize="32px"
          textAlign="center"
          color="white"
          lineHeight="40px"
        >
          {isBrandPage ? t('TRANSLATION_319') : t('VENUE_PAGE_TEXT47')}
        </Text>
        <Button
          variant="orange"
          h="48px"
          mt={{ base: '16px', md: '32px' }}
          ml={{ base: 0, md: '8px' }}
          fontFamily="Proxima Nova Semibold"
          fontSize="18px"
          lineHeight="28px"
          onClick={onOpen}
        >
          {t('VENUE_PAGE_TEXT48')}
        </Button>
        <ContactUsModal isOpen={isOpen} onClose={onClose} />
      </Flex>
    </Box>
  )
}

export default RequestDemoSection
