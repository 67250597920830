import { ReactNode } from 'react'

import { Box, BoxProps, Flex, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { transparentize } from 'polished'

import { Colors } from 'theme/constants'

interface NavLinkProps extends BoxProps {
  name: string
  href: string
  icon?: ReactNode
}

const BackgroundMotionDiv = () => (
  <motion.div
    layoutId="underline"
    transition={{ duration: 0.5 }}
    style={{
      backgroundColor: transparentize(0.9, Colors.brand.ultramarine),
      width: '100%',
      height: '34px',
      borderRadius: '4px',
      position: 'absolute',
      top: 0,
      left: 0,
    }}
  />
)

export const NavLink = ({ name, href, icon, ...rest }: NavLinkProps) => {
  const router = useRouter()
  const isSelected = router.pathname === href

  return (
    <Link href={href}>
      <Box
        as="button"
        mr="10"
        h="34px"
        px="8px"
        position="relative"
        color={isSelected ? 'brand.ultramarine' : 'brand.purpleNavy'}
        _hover={{
          borderRadius: '6px',
          backgroundColor: transparentize(0.9, Colors.brand.ultramarine),
        }}
        {...rest}
      >
        {isSelected && <BackgroundMotionDiv />}
        <Flex
          color={isSelected ? 'brand.ultramarine' : 'brand.purpleNavy'}
          alignItems="center"
          _hover={{
            color: 'brand.ultramarine',
            fontFamily: 'Proxima Nova Semibold',
          }}
        >
          {icon && <Flex mr="8px">{icon}</Flex>}
          <Text
            fontSize="16px"
            lineHeight="24px"
            fontFamily={isSelected ? 'Proxima Nova Semibold' : 'Proxima Nova'}
          >
            {name}
          </Text>
        </Flex>
      </Box>
    </Link>
  )
}
