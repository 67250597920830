import { Box, Container, Flex, Heading, Text } from '@chakra-ui/react'
import Image from 'next/image'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { Region } from 'generated/generated-graphql'
import { useRegion } from 'providers/RegionProvider'

import { ParamountLiqourCards } from './ParamountLiqourCards'
import { ParamountLiqourComponentProps } from './types'

export const ParamountLiqourCooperation = ({
  pageType,
}: ParamountLiqourComponentProps) => {
  const { t } = useTranslation('common')
  const { region } = useRegion()

  if (region !== Region.Australia) {
    return null
  }

  return (
    <Box w="full" position="relative" padding="56px 20px">
      <Container maxW="1110px" position="relative" centerContent gap="56px">
        <Flex
          align="center"
          maxW="1100px"
          gap={{ base: '72px', lg: '238px' }}
          wrap={{ base: 'wrap', lg: 'nowrap' }}
        >
          <Flex
            direction="column"
            gap="24px"
            color="brand.secondary.ultramarine800"
          >
            <Heading
              as="h2"
              size="lg"
              lineHeight="31px"
              fontSize="32px"
              fontWeight="400"
            >
              <Trans
                i18nKey={`common:LandingPage.ParamountLiqourCooperation.${pageType}.title`}
                components={{ blue: <Text color="brand.ultramarine" /> }}
              />
            </Heading>
            <Text fontSize="md" fontWeight="500">
              {t(
                `LandingPage.ParamountLiqourCooperation.${pageType}.description`
              )}
            </Text>
          </Flex>
          <Image
            alt="Paramount Liqour and EzTenda"
            width={296}
            height={143}
            src="/landingPage/eztenda_and_paramount.svg"
          />
        </Flex>
        <Flex gap="24px" flexDirection={{ base: 'column', lg: 'row' }}>
          <ParamountLiqourCards pageType={pageType} />
        </Flex>
      </Container>
    </Box>
  )
}
