export enum Feature {
  Spain = 'Spain',
}

export const useIsFeatureEnabled = (feature: Feature) => {
  const enabledFeatures = [
    ...(process.env.NEXT_PUBLIC_SPAIN_REGION_ENABLED === 'true'
      ? [Feature.Spain]
      : []),
  ]

  return enabledFeatures.includes(feature)
}
