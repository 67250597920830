import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import Image from 'next/legacy/image'

interface TestimonialCardProps {
  companyLogo: string
  companyLogoDimensions: { w?: number; h?: number }
  testimonial: string
  ownerImage?: string
  ownerName: string
  note?: string
}

const TestimonialCard = ({
  companyLogo,
  companyLogoDimensions,
  testimonial,
  ownerImage,
  ownerName,
  note,
}: TestimonialCardProps) => (
  <Flex
    w="full"
    maxW={{ base: 'full', lg: '500px' }}
    p="24px"
    backgroundColor="white"
    align="baseline"
    borderColor="brand.mistyGray"
    borderWidth="0.5px"
    borderRadius="12px"
    boxShadow="medium"
  >
    <VStack spacing="16px">
      <Flex h="48px" align="center">
        <Box
          position="relative"
          width={`${companyLogoDimensions?.w || 48}px`}
          height={`${companyLogoDimensions?.h || 48}px`}
        >
          <Image alt="company logo" src={companyLogo} layout="fill" />
        </Box>
      </Flex>
      <Text
        fontFamily="Cocogoose Pro"
        fontSize="18px"
        lineHeight="26px"
        textAlign="center"
      >
        {testimonial}
      </Text>
      <HStack spacing="10px">
        {ownerImage && (
          <Box display="block" width="48px" height="48px">
            <Image
              alt="owner image"
              width={48}
              height={48}
              src={ownerImage}
              layout="fixed"
            />
          </Box>
        )}
        <VStack spacing={0} align={ownerImage ? 'flex-start' : 'center'}>
          <Text fontFamily="Proxima Nova SemiBold">{ownerName}</Text>
          {note && <Text fontSize="12px">{note}</Text>}
        </VStack>
      </HStack>
    </VStack>
  </Flex>
)

export default TestimonialCard
