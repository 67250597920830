import { Link, ListItem, Text, UnorderedList, VStack } from '@chakra-ui/react'
import { capitalize } from 'lodash'

import { PublicFileLinks } from 'constants/publicFiles'
import { Region } from 'generated/generated-graphql'

import { TabsTitles } from './TabTitles'
import { TranslateFunction } from '../../../../next-translate'

export const getBrandQuestions = (
  region: Region,
  t: TranslateFunction<'common'>,
  emailAddress: string
) => {
  const mailTo = `mailto:${emailAddress}`

  return [
    {
      tabName: t(TabsTitles.About),
      questions: [
        {
          title: t('VENUE_FAQ_ABOUT_Q1'),
          content: (
            <UnorderedList>
              <ListItem>{t('VENUE_FAQ_ABOUT_A11')}</ListItem>
              <ListItem>{t('VENUE_FAQ_ABOUT_A12')}</ListItem>
            </UnorderedList>
          ),
        },
        {
          title: t('VENUE_FAQ_ABOUT_Q2'),
          content: (
            <UnorderedList>
              <ListItem>{t('VENUE_FAQ_ABOUT_A21')}</ListItem>
              <ListItem>{t('VENUE_FAQ_ABOUT_A22')}</ListItem>
              <ListItem>{t('VENUE_FAQ_ABOUT_A23')}</ListItem>
            </UnorderedList>
          ),
        },
        {
          title: t('VENUE_FAQ_ABOUT_Q3'),
          content: t('VENUE_FAQ_ABOUT_A3'),
        },
        {
          title: t('VENUE_FAQ_ABOUT_Q4'),
          content: t('BRAND_FAQ_PRICING_A1'),
        },
        {
          title: t('BRAND_FAQ_PRICING_Q2'),
          content: (
            <Text>
              {t('T_AND_C')}{' '}
              <Link
                color="brand.ultramarine"
                href={PublicFileLinks.brandTermsAndConditions?.[region]}
              >
                here
              </Link>
              .
            </Text>
          ),
        },
      ],
    },
    {
      tabName: t(TabsTitles.PricingAndFees),
      questions: [
        {
          title: t('VENUE_FAQ_PRICING_Q1'),
          content: (
            <VStack w="full" align="flex-start">
              <Text>
                Brands can choose from one of two monthly subscription plans:
              </Text>
              <Text fontFamily="Proxima Nova Semibold">
                Standard - £75pm / category
              </Text>
              <UnorderedList>
                <ListItem ml="20px">
                  Best for brands who generally bid on Standard Listings,
                  Featured Drinks and Events tenders.
                </ListItem>
                <ListItem ml="20px">
                  Bid with unlimited SKUs per category in your subscription.
                </ListItem>
                <ListItem ml="20px">
                  Flexible subscription, upgrade at any time.
                </ListItem>
              </UnorderedList>
              <Text fontFamily="Proxima Nova Medium">
                Premium - £250pm / category
              </Text>
              <UnorderedList>
                <ListItem ml="20px">
                  Best for brands bidding on all tenders, including House Pours.
                </ListItem>
                <ListItem ml="20px">
                  Bid with unlimited SKUs per category in your subscription.
                </ListItem>
              </UnorderedList>
              <Text>
                Some categories may have temporary discounts available.
              </Text>
              <Text>
                For Enterprise & Data packages, or any questions about our fees,
                get in touch at{' '}
                <Link color="brand.ultramarine" href={mailTo}>
                  {emailAddress}
                </Link>
                .
              </Text>
            </VStack>
          ),
        },
        {
          title:
            "Will EzTenda's fees affect the amount of brand support in my bid?",
          content:
            'No. EzTenda’s fees should be treated like other overheads you incur, which don’t affect brand support.',
        },
        {
          title: 'Do you charge venues to use EzTenda?',
          content:
            'No. EzTenda is free for venues provided they abide by the platform rules. Failure to abide by the rules could result in a £250 tender hosting charge for the venue and see both you and the venue being blocked from EzTenda.',
        },
      ],
    },
    {
      tabName: t(TabsTitles.PlatformRules),
      questions: [
        {
          title: t('VENUE_FAQ_PLATFORM_Q1'),
          content: t('BRAND_FAQ_PLATFORM_A1'),
        },
        {
          title: t('BRAND_FAQ_PLATFORM_Q2'),
          content: t('BRAND_FAQ_PLATFORM_A2'),
        },
        {
          title: t('BRAND_FAQ_PLATFORM_Q3'),
          content: t('BRAND_FAQ_PLATFORM_A3'),
        },
        {
          title: t('BRAND_FAQ_PLATFORM_Q4'),
          content: t('BRAND_FAQ_PLATFORM_A4'),
        },
      ],
    },
    {
      tabName: t(TabsTitles.AccountUsers),
      questions: [
        {
          title: t('BRAND_FAQ_ACCOUNT_Q1'),
          content: t('BRAND_FAQ_ACCOUNT_A1'),
        },
        {
          title: t('BRAND_FAQ_ACCOUNT_Q2'),
          content: (
            <Text>
              {t('CONTACT_3')}{' '}
              <Link color="brand.ultramarine" href={mailTo}>
                {emailAddress}
              </Link>{' '}
              {t('CONTACT_4')}
            </Text>
          ),
        },
        {
          title: t('BRAND_FAQ_ACCOUNT_Q3'),
          content: (
            <UnorderedList>
              <ListItem>{t('BRAND_FAQ_ACCOUNT_A31')}</ListItem>
              <ListItem>
                {t('CONTACT_5')}{' '}
                <Link color="brand.ultramarine" href={mailTo}>
                  {emailAddress}
                </Link>{' '}
                {t('CONTACT_4')}
              </ListItem>
            </UnorderedList>
          ),
        },
      ],
    },
    {
      tabName: t(TabsTitles.BrandSupport),
      questions: [
        {
          title: t('VENUE_FAQ_SUPPORT_Q1'),
          content: t('BRAND_FAQ_SUPPORT_A1'),
        },
        {
          title: t('BRAND_FAQ_SUPPORT_Q2'),
          content: t('BRAND_FAQ_SUPPORT_A2'),
        },
      ],
    },
    {
      tabName: capitalize(t(TabsTitles.Bids)),
      questions: [
        {
          title: t('BRAND_FAQ_BIDS_Q1'),
          content: t('BRAND_FAQ_BIDS_A1'),
        },
        {
          title: t('BRAND_FAQ_BIDS_Q2'),
          content: t('BRAND_FAQ_BIDS_A2'),
        },
        {
          title: t('BRAND_FAQ_BIDS_Q3'),
          content: t('BRAND_FAQ_BIDS_A3'),
        },
        {
          title: t('BRAND_FAQ_BIDS_Q4'),
          content: (
            <Text>
              {t('CONTACT_6')}{' '}
              <Link color="brand.ultramarine" href={mailTo}>
                {emailAddress}
              </Link>{' '}
              {t('CONTACT_7')}
            </Text>
          ),
        },
      ],
    },
    {
      tabName: t(TabsTitles.ListingAgreement),
      questions: [
        {
          title: t('VENUE_FAQ_LISTING_Q1'),
          content: (
            <UnorderedList>
              <ListItem>{t('VENUE_FAQ_LISTING_A11')}</ListItem>
              <ListItem>{t('BRAND_FAQ_LISTING_A12')}</ListItem>
            </UnorderedList>
          ),
        },
        {
          title: t('BRAND_FAQ_LISTING_Q2'),
          content: t('BRAND_FAQ_LISTING_A2'),
        },
      ],
    },
  ]
}
