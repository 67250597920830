import React, { useEffect } from 'react'

import { Box, Spinner, Stack } from '@chakra-ui/react'
import { useRouter } from 'next/router'

import { Route } from 'constants/routes'
import { Region } from 'generated/generated-graphql'
import { useUserLocationCountry } from 'hooks/useUserLocationCountry'
import { useLoading } from 'providers/LoadingProvider'
import { useRegion } from 'providers/RegionProvider'

import BenefitSection from './BenefitSection/BenefitSection'
import { BrandSupportCalculatorSection } from './BrandSupportCalculatorSection/BrandSupportCalculatorSection'
import FaqSection from './FAQSection/FaqSection'
import { Footer } from './Footer'
import GallerySection from './GallerySection'
import HeaderSection from './HeaderSection'
import { NavBarLandingPage } from './NavBar/NavBarLandingPage'
import { ParamountLiqourCooperation } from './ParamountLiqour/ParamountLiqourCooperation'
import { PartnersSlider } from './PartnersSlider'
import RequestDemoSection from './RequestDemoSection'
import TestimonialSection from './TestimonialSection/TestimonialSection'
import VideoSection from './VideoSection'

const LandingPage = () => {
  const router = useRouter()
  const { region } = useRegion()

  const isUnitedKingdomPage = region === Region.UnitedKingdom
  const isBrandPage = router.pathname === Route.BrandLandingPage()
  useUserLocationCountry()

  const { isLoading, finishLoading } = useLoading()

  // We show loading spinner to users in case the region is changed to give them feedback that something has changed on the loading page
  // reason: currently (6. feb 2024), UK and AU landing pages are almost the same, so users may think, there is no change on the landing page
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isLoading) {
        finishLoading()
      }
    }, 200)

    return () => clearTimeout(timeout)
  }, [finishLoading, isLoading])

  return (
    <Box width="full" minHeight="100vh" backgroundColor="brand.white">
      {isLoading ? (
        <Stack height="100vh" justify="center" align="center">
          <Spinner size="xl" />
        </Stack>
      ) : (
        <>
          <NavBarLandingPage />
          <HeaderSection isBrandPage={isBrandPage} />
          <ParamountLiqourCooperation
            pageType={isBrandPage ? 'brand' : 'venue'}
          />
          {isBrandPage && isUnitedKingdomPage && <PartnersSlider />}
          {!isBrandPage && <GallerySection isBrandPage={isBrandPage} />}
          <VideoSection isBrandPage={isBrandPage} />
          <Box
            w="full"
            bgGradient="linear(to-t, #F7F7FB, white)"
            {...(!isUnitedKingdomPage && { pb: '130px' })}
          >
            <BenefitSection isBrandPage={isBrandPage} />
            {isUnitedKingdomPage && (
              <TestimonialSection isBrandPage={isBrandPage} />
            )}
          </Box>
          {!isBrandPage && <BrandSupportCalculatorSection />}
          <RequestDemoSection isBrandPage={isBrandPage} />
          <FaqSection isBrandPage={isBrandPage} />
          <Footer isBrandPage={isBrandPage} />
        </>
      )}
    </Box>
  )
}

export default LandingPage
