import { UrlObject } from 'url'

import { Text, TextProps } from '@chakra-ui/react'
import Link from 'next/link'

interface NextLinkProps extends TextProps {
  href: string | UrlObject
}

export const NextLink = ({
  href,
  children,
  tabIndex,
  ...rest
}: NextLinkProps) => (
  <Link href={href} passHref tabIndex={tabIndex}>
    {typeof children === 'string' ? (
      <Text variant="link" tabIndex={tabIndex} {...rest}>
        {children}
      </Text>
    ) : (
      children
    )}
  </Link>
)
