import { Children, ReactNode, useCallback, useRef, useState } from 'react'

import { Box, Flex } from '@chakra-ui/react'
import {
  animate,
  PanInfo,
  useMotionValue,
  ValueAnimationTransition,
} from 'framer-motion'

import { useIsOnMobile } from 'hooks/useIsOnMobile'

import { Arrow } from './Arrow'
import { Slider } from './Slider'

interface CarouselProps {
  children: ReactNode
  hasArrows?: boolean
  loop?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Transition: ValueAnimationTransition = {
  type: 'spring',
  bounce: 0,
}

export const Carousel = ({
  children,
  hasArrows = true,
  loop = true,
}: CarouselProps) => {
  const draggableAxis = useMotionValue(0)
  const sliderWrapperRef = useRef<HTMLDivElement>(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const isOnMobile = useIsOnMobile()

  const calculateNewXDraggableAxis = useCallback(
    () => -currentSlide * (sliderWrapperRef.current?.clientWidth || 0),
    [currentSlide]
  )

  const stopDrag = () => {
    const controls = animate(
      draggableAxis,
      calculateNewXDraggableAxis(),
      Transition
    )
    return controls.stop
  }

  const childrens = Children.toArray(children)
  const isLastSlide = currentSlide + 1 === childrens.length
  const isFirstSlide = currentSlide - 1 < 0

  const handleNext = () => {
    const idx = loop ? 0 : currentSlide
    setCurrentSlide(isLastSlide ? idx : currentSlide + 1)
    stopDrag()
  }

  const handlePrev = () => {
    const idx = loop ? childrens.length - 1 : 0
    setCurrentSlide(isFirstSlide ? idx : currentSlide - 1)
    stopDrag()
  }

  const handleEndDrag = (_e: Event, dragProps: PanInfo) => {
    const carouselWidth = sliderWrapperRef.current?.clientWidth || 0

    const { offset } = dragProps

    if (offset.x > carouselWidth / 4) {
      handlePrev()
    } else if (offset.x < -carouselWidth / 4) {
      handleNext()
    } else {
      stopDrag()
    }
  }

  return (
    <Flex position="relative" mb="32px" justifyContent="space-between">
      {isOnMobile && hasArrows && (
        <Arrow isLeft onClick={handlePrev} isOnMobile={isOnMobile} />
      )}
      <Box
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          overflowX: 'hidden',
          display: 'flex',
        }}
        marginX={{ base: '10px', lg: 0 }}
        ref={sliderWrapperRef}
      >
        {childrens.map((element, index) => (
          <Slider
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            onDragEnd={handleEndDrag}
            draggableAxis={draggableAxis}
          >
            {element}
          </Slider>
        ))}
      </Box>
      {hasArrows && (
        <>
          {!isOnMobile && (
            <Arrow isLeft onClick={handlePrev} isOnMobile={isOnMobile} />
          )}
          <Arrow onClick={handleNext} isOnMobile={isOnMobile} />
        </>
      )}
    </Flex>
  )
}
