import {
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'
import Image from 'next/legacy/image'
import useTranslation from 'next-translate/useTranslation'

import { useIsOnMobile } from 'hooks/useIsOnMobile'

import Benefit from './Benefit'
import { TranslateFunction } from '../../../../next-translate.d'

const ICON_SIZE = 48
const getBenefitData = (t: TranslateFunction<'common'>) => ({
  brand: [
    {
      iconSrc: '/landingPage/benefitIcons/lock.svg',
      description: t('BRAND_PAGE_TEXT12'),
    },
    {
      iconSrc: '/landingPage/benefitIcons/ribbon.svg',
      description: t('BRAND_PAGE_TEXT13'),
    },
    {
      iconSrc: '/landingPage/benefitIcons/graph.svg',
      description: t('BRAND_PAGE_TEXT14'),
    },
    {
      iconSrc: '/landingPage/benefitIcons/grid.svg',
      description: t('BRAND_PAGE_TEXT15'),
    },
  ],
  venue: [
    {
      iconSrc: '/landingPage/benefitIcons/graph.svg',
      description: t('VENUE_PAGE_TEXT20'),
    },
    {
      iconSrc: '/landingPage/benefitIcons/lock.svg',
      description: t('VENUE_PAGE_TEXT21'),
    },
    {
      iconSrc: '/landingPage/benefitIcons/grid.svg',
      description: t('VENUE_PAGE_TEXT22'),
    },
    {
      iconSrc: '/landingPage/benefitIcons/focus.svg',
      description: t('VENUE_PAGE_TEXT23'),
    },
  ],
})

interface BenefitSectionProps {
  isBrandPage: boolean
}

const BenefitSection = ({ isBrandPage }: BenefitSectionProps) => {
  const { t } = useTranslation('common')
  const isOnMobile = useIsOnMobile()

  const benefitData = getBenefitData(t)

  return (
    <Box w="full">
      <Container maxW="1040px" centerContent>
        {isOnMobile ? (
          <Flex
            mt={{ base: '32px', md: '40px' }}
            w="full"
            justifyContent="center"
          >
            <Flex
              px={{ base: '16px', md: 0 }}
              maxW="860px"
              gridColumnGap="40px"
              gridRowGap={{ base: '12px', md: '32px' }}
              wrap="wrap"
              justifyContent="center"
              zIndex="1"
            >
              {benefitData[isBrandPage ? 'brand' : 'venue'].map(
                ({ iconSrc, description }) => (
                  <Benefit
                    key={description}
                    iconSrc={iconSrc}
                    description={description}
                  />
                )
              )}
            </Flex>
          </Flex>
        ) : (
          <Flex
            mt={{ base: '32px', md: '40px' }}
            backgroundColor="white"
            borderRadius="10px"
            boxShadow="medium"
            alignItems="baseline"
          >
            <HStack
              px="34px"
              alignItems="baseline"
              divider={
                <Divider
                  orientation="vertical"
                  w="1px"
                  borderColor="brand.mistyGray"
                />
              }
            >
              {benefitData[isBrandPage ? 'brand' : 'venue'].map(
                ({ iconSrc, description }) => (
                  <VStack w="full" spacing="12px" py="19px" key={description}>
                    <Box w="48px" h="48px">
                      <Image
                        alt="benefit icon"
                        src={iconSrc}
                        width={ICON_SIZE}
                        height={ICON_SIZE}
                        layout="fixed"
                      />
                    </Box>
                    <Text
                      fontFamily="Proxima Nova Semibold"
                      fontSize="18px"
                      lineHeight="20px"
                      align="center"
                    >
                      {description}
                    </Text>
                  </VStack>
                )
              )}
            </HStack>
          </Flex>
        )}
      </Container>
    </Box>
  )
}

export default BenefitSection
