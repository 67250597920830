import { Circle } from '@chakra-ui/react'

import { Arrow as ArrowIcon } from 'components/icons/Arrow'

interface ArrowProps {
  size?: number
  onClick: () => void
  isLeft?: boolean
  isOnMobile?: boolean
}

export const Arrow = ({
  size = 32,
  isLeft = false,
  isOnMobile,
  onClick,
}: ArrowProps) => (
  <Circle
    position={isOnMobile ? 'relative' : 'absolute'}
    size={`${size}px`}
    top="50%"
    transform="translateY(-50%)"
    cursor="pointer"
    display="flex"
    alignItems="center"
    justifyContent="center"
    bg="brand.orange"
    onClick={onClick}
    alignSelf="center"
    {...(!isOnMobile && {
      left: isLeft ? `-${size + 40}px` : 'initial',
      right: !isLeft ? `-${size + 40}px` : 'initial',
    })}
  >
    <ArrowIcon
      color="brand.white"
      {...(isLeft && { transform: 'rotate(180deg)' })}
    />
  </Circle>
)
