import { ReactNode } from 'react'

import { Box, Circle, Flex, FlexProps, Text, VStack } from '@chakra-ui/react'
import { motion } from 'framer-motion'

interface GalleryButtonProps extends FlexProps {
  title: string
  highlight: string
  isSelected: boolean
  icon: ReactNode
}

export const GalleryButton = ({
  title,
  highlight,
  isSelected,
  icon,
  ...rest
}: GalleryButtonProps) => (
  <Flex
    as="div"
    key={title}
    w="full"
    maxW={{ base: 'full', md: '410px', lg: '410px' }}
    borderRadius="12px"
    alignItems="center"
    position="relative"
    p="24px"
    cursor="pointer"
    _hover={{
      bg: 'white',
      borderColor: 'brand.mistyGray',
      boxShadow: 'medium',
    }}
    {...rest}
  >
    {isSelected && (
      <Box
        as={motion.div}
        position="absolute"
        layoutId="underline"
        transition={{ duration: '0.5' }}
        backgroundColor="white"
        w="full"
        top={0}
        left={0}
        h="96px"
        borderColor="brand.mistyGray"
        borderWidth="1px"
        borderRadius="16px"
        boxShadow="medium"
        zIndex={1}
      />
    )}
    <Circle
      zIndex={2}
      bg="brand.fogGray"
      size="48px"
      mr="18px"
      color="brand.ultramarine"
    >
      {icon}
    </Circle>
    <VStack zIndex={2} spacing="8px" align="start">
      <Text fontSize="16px" lineHeight="16px">
        {title}
      </Text>
      <Text
        fontSize="18px"
        fontFamily="Proxima Nova SemiBold"
        color="brand.ultramarine"
        lineHeight="18px"
      >
        {highlight}
      </Text>
    </VStack>
  </Flex>
)
