import { useState } from 'react'

import { Box, Button, Container, Flex, Text } from '@chakra-ui/react'
import Image from 'next/legacy/image'
import useTranslation from 'next-translate/useTranslation'

import { Play } from 'components/icons/Play'
import { Region } from 'generated/generated-graphql'
import { useRegion } from 'providers/RegionProvider'

const UK_YOUTUBE_URL = 'https://www.youtube.com/embed/xkxa--4KT7U?&autoplay=1'
const AUS_YOUTUBE_URL = 'https://www.youtube.com/embed/9RWqbtdsmvk?&autoplay=1'

const VideoSection = ({ isBrandPage }: { isBrandPage: boolean }) => {
  const { t } = useTranslation('common')
  const { region } = useRegion()
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)

  return (
    <Box w="full">
      <Container maxW="1110px" centerContent>
        <Text
          maxW="580px"
          mt={{ base: '64px', md: '80px' }}
          mb="30px"
          fontFamily="Cocogoose Pro"
          fontSize={{ base: '24px', md: '32px' }}
          lineHeight={{ base: '30px', md: '41px' }}
          color="brand.ultramarine"
          textAlign="center"
        >
          {isBrandPage ? t('BRAND_PAGE_TEXT11') : t('VENUE_PAGE_TEXT18')}
        </Text>
        <Flex
          position="relative"
          w="full"
          maxW="1044px"
          h="auto"
          justifyContent="center"
          align="center"
          display="block"
          // This pt sets aspect ratio to 16:9
          {...(isVideoPlaying && {
            pt: '56.25%',
          })}
        >
          {!isVideoPlaying && (
            <>
              <Image
                alt="video thumbnail"
                width={1044}
                height={540}
                src="/landingPage/video_thumbnail_compressed.jpg"
                layout="responsive"
              />

              <Button
                position="absolute"
                top={0}
                bottom={0}
                left={0}
                right={0}
                margin="auto"
                width="150px"
                leftIcon={<Play />}
                variant="lightBlue"
                onClick={() => {
                  setIsVideoPlaying(true)
                }}
              >
                {t('VENUE_PAGE_TEXT19')}
              </Button>
            </>
          )}
          {isVideoPlaying && (
            <iframe
              style={{ position: 'absolute', top: 0 }}
              id="EzTendaYouTube"
              title="ezTenda"
              width="100%"
              height="100%"
              src={
                region === Region.Australia ? AUS_YOUTUBE_URL : UK_YOUTUBE_URL
              }
              frameBorder="0"
              allowFullScreen
            />
          )}
        </Flex>
      </Container>
    </Box>
  )
}

export default VideoSection
