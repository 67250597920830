import {
  Box,
  Center,
  Container,
  Flex,
  HStack,
  Spacer,
  useBreakpointValue,
} from '@chakra-ui/react'
import { LayoutGroup } from 'framer-motion'
import Image from 'next/legacy/image'
import useTranslation from 'next-translate/useTranslation'

import { NextButton } from 'components/common/NextButton'
import { NextLink } from 'components/common/NextLink'
import { RegionSelector } from 'components/common/RegionSelector'
import { NavLink } from 'components/NavBar/NavLink'
import { Route } from 'constants/routes'
import { zIndex } from 'constants/zIndex'
import { useLoading } from 'providers/LoadingProvider'
import { useAuth } from 'providers/UserProvider'

import { NavBarMobileDrawer } from './NavBarMobileDrawer'

export const NavBarLandingPage = () => {
  const { t } = useTranslation('common')
  const { userData } = useAuth()
  const isOnMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: false,
  })

  const { startLoading } = useLoading()

  return (
    <Box
      top="0px"
      position="sticky"
      zIndex={zIndex.Navbar}
      h="81px"
      borderBottom="2px solid"
      borderColor="brand.brightGray"
      backgroundColor="white"
    >
      <Container maxWidth="1110px">
        <Flex h="81px">
          <Flex {...(!userData && { w: '271px' })}>
            <Center h="full" cursor="pointer">
              <Image
                alt="logo"
                src="/logo.svg"
                width={145}
                height={29}
                layout="fixed"
              />
            </Center>
          </Flex>
          <Spacer />
          {isOnMobile ? (
            <NavBarMobileDrawer />
          ) : (
            <>
              <LayoutGroup>
                <Flex align="center">
                  <NavLink
                    name={t('VENUE_PAGE_TEXT1')}
                    href={Route.VenueLandingPage()}
                    ml={{ sm: '15px', md: 0 }}
                    mr={{ sm: '20px', md: '45px' }}
                  />
                  <NavLink
                    name={t('VENUE_PAGE_TEXT2')}
                    href={Route.BrandLandingPage()}
                    mr={{ sm: '15px', md: 0 }}
                  />
                </Flex>
              </LayoutGroup>
              <Spacer />
              <HStack spacing="32px">
                <HStack justify="right" spacing="16px">
                  {userData ? (
                    <NextButton
                      fontFamily="Proxima Nova Semibold"
                      fontSize="16px"
                      lineHeight="24px"
                      href={Route.TenderList()}
                    >
                      {t('TRANSLATION_297')}
                    </NextButton>
                  ) : (
                    <>
                      <NextLink
                        fontFamily="Proxima Nova Semibold"
                        fontSize="16px"
                        lineHeight="24px"
                        href={Route.SignIn()}
                        color="brand.ultramarine"
                        px="16px"
                      >
                        {t('VENUE_PAGE_TEXT3')}
                      </NextLink>
                      <NextButton
                        fontFamily="Proxima Nova Semibold"
                        fontSize="16px"
                        lineHeight="24px"
                        href={Route.SignUp()}
                      >
                        {t('VENUE_PAGE_TEXT4')}
                      </NextButton>
                    </>
                  )}
                </HStack>
                {!userData && (
                  <RegionSelector
                    hasPopoverTitle
                    onRegionChange={startLoading}
                  />
                )}
              </HStack>
            </>
          )}
        </Flex>
      </Container>
    </Box>
  )
}
