import { Box, Flex, HStack, Text } from '@chakra-ui/react'
import Image from 'next/legacy/image'
import { transparentize } from 'polished'

import { Colors } from 'theme/constants'

const ICON_SIZE = 48

interface BenefitProps {
  iconSrc: string
  description: string
}

const Benefit = ({ iconSrc, description }: BenefitProps) => (
  <Flex
    width="full"
    maxW={{ base: 'full', md: '410px' }}
    h="96px"
    p="24px"
    backgroundColor="white"
    align="center"
    borderRadius="10px"
    boxShadow={`0px 7.5px 22px 0px ${transparentize(0.95, Colors.brand.black)}`}
  >
    <HStack spacing="24px">
      <Box w="48px" h="48px">
        <Image
          alt="benefit icon"
          src={iconSrc}
          width={ICON_SIZE}
          height={ICON_SIZE}
          layout="fixed"
        />
      </Box>
      <Text
        fontFamily="Proxima Nova Semibold"
        fontSize="18px"
        lineHeight="22px"
      >
        {description}
      </Text>
    </HStack>
  </Flex>
)

export default Benefit
