import { ReactNode } from 'react'

import { Box, Stack, StackProps, Text, TextProps } from '@chakra-ui/react'
import Image from 'next/legacy/image'

interface LinkProps extends TextProps {
  href: string
  icon?: ReactNode
  iconUrl?: string
  iconPosition?: 'left' | 'right'
  isTruncated?: boolean
  isOpenInNewTab?: boolean
  spacing?: StackProps['spacing']
  color?: TextProps['color']
}

export const Link = ({
  href,
  children,
  icon,
  iconUrl,
  color = 'brand.ultramarine',
  isOpenInNewTab = false,
  isTruncated = true,
  iconPosition = 'right',
  spacing = '6px',
  ...rest
}: LinkProps) => (
  <a
    href={href}
    target={isOpenInNewTab ? '_blank' : '_self'}
    rel="noopener noreferrer"
  >
    <Stack
      alignItems="center"
      direction={iconPosition === 'left' ? 'row-reverse' : 'row'}
      spacing={spacing}
    >
      <Text
        variant="link"
        isTruncated={isTruncated}
        textDecoration="underline"
        color={color}
        {...rest}
      >
        {children}
      </Text>
      {icon || iconUrl ? (
        <Box minW="12px" lineHeight="12px">
          {icon ||
            (iconUrl && (
              <Image alt="icon" src={iconUrl} height={12} width={12} />
            ))}
        </Box>
      ) : null}
    </Stack>
  </a>
)
