import { Flex, Text } from '@chakra-ui/react'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

import { ParamountLiqourComponentProps } from './types'

export const ConnectCard = ({ pageType }: ParamountLiqourComponentProps) => {
  const { t } = useTranslation('common')

  return (
    <Flex padding="24px" direction="column" gap="12px">
      <Flex gap="5px" alignItems="center">
        <Image alt="Match" height={24} width={33.41} src="/icons/connect.svg" />
        <Text
          fontSize="lg"
          fontWeight="600"
          color="brand.secondary.ultramarine900"
        >
          {t('LandingPage.ParamountLiqourCooperation.Connect.title')}
        </Text>
      </Flex>
      <Text
        fontSize="sm"
        lineHeight="110%"
        fontWeight="400"
        color="brand.secondary.ultramarine800"
      >
        {t(
          `LandingPage.ParamountLiqourCooperation.Connect.${pageType}.description`
        )}
      </Text>
    </Flex>
  )
}
